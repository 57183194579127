/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react';
import { ColorPaletteProp } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Avatar from '@mui/joy/Avatar';
import Chip from '@mui/joy/Chip';
import Link from '@mui/joy/Link';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  GetAllOrganizationDevices, 
  GetDevice,
  CreateDevice,
  UpdateDevice,
  DeleteDevice,
  Device
} from '../../api/devices';

import { Button, FormControl, FormLabel, Input, Modal, ModalClose, ModalDialog, Select, Sheet, Option } from '@mui/joy';
import DataGridLoading from '../../components/DataGridLoading';

import dayjs from 'dayjs';

export default function DevicesList(props:any) {
  const { devices = [], setEditId, setDeleteId } = props;
  const [tmpDevices, setTmpDevices] = useState<Device[]>(devices);
  const [searchByName, setSearchByName] = useState('');
  const [open, setOpen] = useState(false);  

  const [selectedStatus, setSelectedStatus] = useState<string>('');  
  const [selectedStore, setSelectedStore] = useState<string>(''); 
  

  useEffect(() => {
    setTmpDevices(devices);
  }, [devices]);

  useEffect(() => {
      setTmpDevices(devices.filter((item:Device) => 
        item?.deviceName && item?.store
          && ((item?.deviceName?.toLowerCase()+item?.store?.toLowerCase()).includes(searchByName.toLowerCase())
          && (selectedStatus.length ? (item?.deviceStatus?.toLowerCase() === selectedStatus.toLowerCase()) : true)
          && (selectedStore.length ? (item?.storeId?.toLowerCase() === selectedStore.toLowerCase()) : true)
      )));
  }, [searchByName, selectedStatus, selectedStore]);

  const RowMenu = (props:any) => {
    const { id } = props;
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
            <MenuItem onClick={() => setEditId(id)}>Edit</MenuItem>
            <Divider />
            <MenuItem color="danger" onClick={() => setDeleteId(id)}>Delete</MenuItem>
        </Menu>
      </Dropdown>
    );
  }

  const renderFilters = () => (
    <Fragment>
      <FormControl size="sm">
        <FormLabel>Status</FormLabel>
        <Select
          variant='soft'
          size="sm" disabled
          placeholder="Filter by status"
          slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
        >
          <Option value="true">Active</Option>
          <Option value="false">Inactive</Option>
        </Select>
      </FormControl>
    </Fragment>
  );
  
  return (
    <Fragment>
    {/* <Sheet
      className="SearchAndFilters-mobile"
      sx={{
        display: { xs: 'flex', sm: 'none' },
        my: 1,
        gap: 1,
      }}
    >
      <Input
        size="sm"
        variant='soft'
        placeholder="Search"
        value={searchByName} onChange={e => setSearchByName(e.target.value)}
        startDecorator={<SearchIcon />}
        sx={{ flexGrow: 1 }}
      />
      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        onClick={() => setOpen(true)}
      >
        <FilterAltIcon />
      </IconButton>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
          <ModalClose />
          <Typography id="filter-modal" level="h2">
            Filters
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {renderFilters()}
            <Button color="primary" onClick={() => setOpen(false)}>
              Submit
            </Button>
          </Sheet>
        </ModalDialog>
      </Modal>
    </Sheet> */}
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      {tmpDevices && tmpDevices.map((device:Device) => (
        <List
          key={device?.id}
          size="sm"
          sx={{
            '--ListItem-paddingX': 0,
          }}
        >
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'start',
            }}
          >
            <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
              <ListItemDecorator>
                <Avatar size="sm">{device?.deviceName?.slice(0,1)}</Avatar>
              </ListItemDecorator>
              <div>
                <Typography fontWeight={600} gutterBottom>
                  {device?.deviceName}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 0.5,
                    mb: 1,
                  }}
                >
                  <Typography level="body-xs">{device?.deviceType} | {device?.deviceStatus}</Typography>
                </Box>
              </div>
            </ListItemContent>
            <Chip
              variant="soft"
              size="sm"
              style={{top: '5px'}}
                    color='neutral'
            >
              { device.status }
            </Chip>
            <RowMenu id={device?.id}  />
          </ListItem>
          <ListDivider />
        </List>
      ))}
      <DataGridLoading />
      <Box
        className="Pagination-mobile"
        sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', py: 2 }}
      >
        <IconButton
          aria-label="previous page"
          variant="outlined"
          color="neutral"
          size="sm"
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Typography level="body-sm" mx="auto">
          Page 1 of 10
        </Typography>
        <IconButton
          aria-label="next page"
          variant="outlined"
          color="neutral"
          size="sm"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
    </Fragment>
  );
}