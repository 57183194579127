import axios from "axios";
import headers from "./headers";
import redirect from "./redirect";
import uuid from "../res/uuid";

// https://device.api.acesolutions.no/api/Swagger/ui#/

export interface Device {
    id?: string | null,
    organizationId?: string | null,
    storeId?: string | null,
    store?: string,
    deviceName?: string,
    deviceType?: string,
    zoneId?: string | null,
    ioTDeviceId?: string | null,
    ioTPrimaryConnectionString?: string,
    ioTSecondaryConnectionString?: string,
    ioTPrimaryKey?: string,
    ioTSecondaryKey?: string,
    certificate?: string,
    status?: string,
    installedAt?: string,
    networkInfo: {
      ipAddress?: string | null,
      ipV6Address?: string | null,
      macAddress?: string | null,
    },
    serviceContractInfo?: {
      contractId?: string | null,
      contractLevel?: string | null,
      expirationDate?: string | null,
    },
    deviceIlustrationUrl?: string,
    entryUUID?: string | null,
    exitUUID?: string | null,
    deviceStatus?: string,
    encodedDeviceData?: string,
    deviceSchema?: object,
    deviceUiSchema?: object,
    deviceScripts?: {
        scriptId: string,
        trigger: string,
        scriptName: string,
        scriptTemplateName: string,
    }[]
    deviceScriptTriggers?: string[],
    deviceScriptTemplates?: {
        scriptId: string,
        trigger: string,
        scriptName: string,
        scriptTemplateName: string,
    }[]
};

export const defaultDevice:Device = {
    id: uuid(),
    organizationId: localStorage.getItem('organization_id') || "",
    storeId: null,
    store: '',
    deviceName: '',
    deviceType: '',
    zoneId: null,
    ioTDeviceId: null,
    ioTPrimaryConnectionString: '',
    ioTSecondaryConnectionString: '',
    ioTPrimaryKey: '',
    ioTSecondaryKey: '',
    certificate: '',
    status: '',
    installedAt: '',
    networkInfo: {
      ipAddress: '',
      ipV6Address: '',
      macAddress: ''
    },
    serviceContractInfo: {
      contractId: '',
      contractLevel: '',
      expirationDate: ''
    },
    deviceIlustrationUrl: '',
    entryUUID: null,
    exitUUID: null,
    deviceStatus: '',
    encodedDeviceData: '',
    deviceSchema: [],
    deviceUiSchema: [],
    deviceScripts: [],
    deviceScriptTriggers: [],
    deviceScriptTemplates: []
};

export const GetAllOrganizationDevices = async (org_id:string): Promise<Device[]> => {
    try {
        const { data } = await axios.get<Device[]>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch devices:', error);
        return [];
    }
}

export const GetDevice = async (org_id:string, deviceId: string): Promise<Device | null> => {
    try {
        const { data } = await axios.get<Device>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices/${deviceId}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch device:', error);
        return null;
    }
}

export const CreateDevice = async (org_id:string, device: Device): Promise<Device | null> => {
    try {
        const { data } = await axios.post<Device>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices`, device, {
            headers: headers(localStorage.getItem('id_token'))
        });     
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to create device:', error);
        return null;
    }
}

export const UpdateDevice = async (org_id: string, device: Device): Promise<Device | null> => {
    try {
        const { data } = await axios.put<Device>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices/${device.id}`, device, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update device:', error);
        return null;
    }
}

export const DeleteDevice = async (org_id: string, id: string): Promise<any> => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices/${id}`, {
            headers: headers(localStorage.getItem('id_token')),
            data: {}
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to delete device:', error);
        return null;
    }
}

export const GetAllDeviceTypes = async (): Promise<string[]> => {
    try {
        const { data } = await axios.get<string[]>(`${process.env.REACT_APP_DEVICE_API}/api/deviceTypes`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch device types:', error);
        return [];
    }
}

export const GetDeviceScript = async (org_id: string, device_id:string, script_id:string): Promise<any> => {
    try {
        const { data } = await axios.get<any>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices/${device_id}/deviceScripts/${script_id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch device scripts:', error);
        return false;
    }
}

export const GetDeviceTypeData = async (device_type:string): Promise<any> => {
    try {
        const { data } = await axios.get<any>(`${process.env.REACT_APP_DEVICE_API}/api/deviceTypes/${device_type}`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch device type data:', error);
        return [];
    }
}

export const GetAllDeviceScriptTemplates = async (device_type:string): Promise<string[]> => {
    try {
        const { data } = await axios.get<string[]>(`${process.env.REACT_APP_DEVICE_API}/api/deviceScripts/templates/${device_type}`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch devices:', error);
        return [];
    }
}

export const GetDeviceTypeScriptTemplate = async (device_type:string, script_template_name:string): Promise<any> => {
    try {
        const { data } = await axios.get<any>(`${process.env.REACT_APP_DEVICE_API}/api/deviceScripts/templates/${device_type}/${script_template_name}`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch device script template:', error);
        return [];
    }
}

export const EvaluateDeviceScript = async (org_id: string, device_id:string, script_id:string, script:any): Promise<any> => {
    try {
        const { data } = await axios.put<any>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices/${device_id}/deviceScripts/${script_id}/evaluate`, script, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch device script evaluation:', error);
        return error;
    }
}

export const UpdateDeviceScript = async (org_id: string, device_id:string, script_id:string, script:any): Promise<any> => {
    try {
        const { data } = await axios.put<any>(`${process.env.REACT_APP_DEVICE_API}/api/organizations/${org_id}/devices/${device_id}/deviceScripts/${script_id}`, script, {
            headers: headers(localStorage.getItem('id_token')),
        });      
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update device script:', error);
        return null;
    }
}
