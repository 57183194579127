/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from 'react';
import Box from '@mui/joy/Box';
import Avatar from '@mui/joy/Avatar';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { Store } from '../../../api/stores';

import DataGridLoading from '../../../components/DataGridLoading';

const StoresList = (props:any) => {
  const { stores = [], setEditId, setDeleteId } = props;
  const [tmpStores, setTmpStores] = useState<Store[]>(stores);
  const [searchByName, setSearchByName] = useState('');

  useEffect(() => {
    setTmpStores(stores);
  }, [stores]);

  useEffect(() => {
      setTmpStores(stores.filter((item:Store) => 
          (item?.name.toLowerCase()).includes(searchByName.toLowerCase()
      )));
  }, [searchByName]);
  
  const RowMenu = (props:any) => {
    const { id } = props;
    return (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
        >
          <MoreHorizRoundedIcon />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
            <MenuItem onClick={() => setEditId(id)}>Edit</MenuItem>
            <Divider />
            <MenuItem color="danger" onClick={() => setDeleteId(id)}>Delete</MenuItem>
        </Menu>
      </Dropdown>
    );
  }
  
  return (
    <Fragment>
      <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
        {tmpStores && tmpStores.map((store:Store) => (
          <List
            key={store.id}
            size="sm"
            sx={{
              '--ListItem-paddingX': 0,
            }}
          >
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
              }}
            >
              <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                <ListItemDecorator>
                  <Avatar size="sm">{store.name.slice(0,1)}</Avatar>
                </ListItemDecorator>
                <div>
                  <Typography fontWeight={600} gutterBottom>
                    {store.name}
                  </Typography>
                </div>
              </ListItemContent>
              <RowMenu id={store.id}  />
            </ListItem>
            <ListDivider />
          </List>
        ))}
        <DataGridLoading />
        <Box
          className="Pagination-mobile"
          sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', py: 2 }}
        >
          <IconButton
            aria-label="previous page"
            variant="outlined"
            color="neutral"
            size="sm"
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography level="body-sm" mx="auto">
            Page 1 of 10
          </Typography>
          <IconButton
            aria-label="next page"
            variant="outlined"
            color="neutral"
            size="sm"
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Box>
      </Box>
    </Fragment>
  );
};

export default StoresList;