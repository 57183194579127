import axios from "axios";
import headers from "./headers";
import redirect from "./redirect";

// https://zone.api.acesolutions.no/api/Swagger/ui#/

export interface Zone {
    id: string,
    organizationId: string,
    storeId?: string | null,
    store: string,
    name: string,
    code?: string | null,
    location?: string | null,
    zoneActivity?: string | null,
    zoneCategory?: string | null,
    zoneIlustrationUrl?: string | null,
    zoneThreshold?: {
      name?: string | null,
      type?: string | null,
    }
};

export const GetAllZones = async (org_id:string): Promise<Zone[]> => {
    try {
        const { data } = await axios.get<Zone[]>(`${process.env.REACT_APP_ZONE_API}/api/organizations/${org_id}/zones`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch zones:', error);
        return [];
    }
}

export const GetZone = async (org_id:string, zoneId: string): Promise<Zone | null> => {
    try {
        const { data } = await axios.get<Zone>(`${process.env.REACT_APP_ZONE_API}/api/organizations/${org_id}/zones/${zoneId}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch zone:', error);
        return null;
    }
}

export const CreateZone = async (org_id:string, zone: Zone): Promise<Zone | null> => {
    try {
        const { data } = await axios.post<Zone>(`${process.env.REACT_APP_ZONE_API}/api/organizations/${org_id}/zones`, zone, {
            headers: headers(localStorage.getItem('id_token'))
        });     
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to create zone:', error);
        return null;
    }
}

export const UpdateZone = async (org_id: string, zone: Zone): Promise<Zone | null> => {
    try {
        const { data } = await axios.put<Zone>(`${process.env.REACT_APP_ZONE_API}/api/organizations/${org_id}/zones/${zone.id}`, zone, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update zone:', error);
        return null;
    }
}

export const DeleteZone = async (org_id: string, id: string): Promise<any> => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_ZONE_API}/api/organizations/${org_id}/zones/${id}`, {
            headers: headers(localStorage.getItem('id_token')),
            data: {}
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to delete zone:', error);
        return null;
    }
}
