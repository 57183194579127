import axios from "axios";
import headers from "./headers";
import redirect from "./redirect";

// https://satisfaction.api.what-software.com/api/apidocs/#/

export interface SatisfactionDevice {
    average: number,
    device_id: string,
    device: string,
    distribution: {
        1: number,
        2: number,
        3: number,
        4: number,
    },
    total: number,
    trend: string,
};

export interface Graph {
    date: string,
    ratings: {
        1: number,
        2: number,
        3: number,
        4: number,
    }
};

export interface Satisfaction {
    devices: SatisfactionDevice[],
    graph: {
        data: Graph[]
    },
    summary: {
        average: number,
        distribution: {
            1: number,
            2: number,
            3: number,
            4: number,
        },
        highest_rated_device: string,
        lowest_rated_device: string,
        total: number,
        trend: string,
    },
    tenant_id: string
};

export const GetAllSatisfactions = async (org_id:string): Promise<Satisfaction | null> => {
    try {
        const { data } = await axios.get<Satisfaction>(`${process.env.REACT_APP_SATISFACTION_API}/api/v1/management/satisfaction/${org_id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch satisfactions:', error);
        return null;
    }
}
