import React, {useState, useEffect, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import { Box,
    Button,
    Stack, 
    DialogTitle, 
    DialogContent, 
    LinearProgress, 
    Stepper, 
    Step, 
    StepButton, 
    StepIndicator, 
    FormControl, 
    FormLabel, 
    Input, 
    Modal, 
    ModalDialog, 
    ModalClose, 
    Select, 
    Option
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../store/loadingSlice'
import { toast } from 'react-toastify';
import Layout from '../../template/layout';
import UsersList from './UsersList';
import {
    GetAllUserProfiles, 
    GetUserProfile, 
    DeleteUserProfile, 
    UpdateUserProfile, 
    User, 
    UserProfile
} from '../../api/users';
import countries from '../../res/countries';
import AppDeleteItemModal from '../../components/AppDeleteItemModal';
import AceGrid, { HeadCell } from '../../components/AceGrid';
import AppHeader from '../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../components/AppBreadcrumb';

const Users = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState<User[]>([]);
    const [tmpUsers, setTmpUsers] = useState<User[]>(users);
    const [openCreateUpdateModal, setOpenCreateUpdateModal] = useState(false);  
    const [modalUser, setModalUser] = useState<UserProfile | null>(null); 
    const [modalUserStep, setModalUserStep] = useState(0); 
    const [modalLoading, setModalLoading] = useState(false);
    const [editId, setEditId] = useState<string | null>(null); 
    const [deleteId, setDeleteId] = useState<string | null>(null); 
    const organization_id = localStorage.getItem('organization_id') || "";
    const [searchByName, setSearchByName] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);  

    const steps = ['', '', ''];   

    useEffect(() => {
        setTmpUsers(users);
    }, [users]);
  
    useEffect(() => {
        setTmpUsers(users.filter((item:User) => 
            ((item?.userName.toLowerCase()+item?.fullName.toLowerCase()+item.email.toLowerCase()).includes(searchByName.toLowerCase())
        )));
    }, [searchByName]);

    const dispatch = useDispatch();
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };
  
    const fetchUserProfiles = async () => {
        setLoading(true);
        setUsers([]);
        GetAllUserProfiles()
            .then(users => {
                setUsers(users);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchUserProfile = async (id: string) => {
        if(id){
            setOpenCreateUpdateModal(true);
            setModalLoading(true);
            GetUserProfile(id)
                .then(user => {
                    setModalUser(user);
                    setModalLoading(false);
                    setEditId(null);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };

    const deleteUserProfile = async (id: string) => {
      id && await DeleteUserProfile(id);
      setOpenCreateUpdateModal(false);
      setDeleteId(null);
    };
  
    const updateUserProfile = async (id: string, user: UserProfile) => {
      try {
        const data = await UpdateUserProfile(id, user);
        fetchUserProfiles();
        setModalUser(null);
        setModalUserStep(0);
        setOpenCreateUpdateModal(false);
      } catch (error) {
        console.log(error);
      }
    };
  
    useEffect(() => {
        setUsers([]);
      fetchUserProfiles();
    },[]);
  
    useEffect(() => {     
        editId && fetchUserProfile(editId);      
    },[editId]);
    
    const setDeleteList = (data:string[]) => {
        // console.log("Delete List: ", data);  
        toast.warning("We need to update API!");
    };

    const headCells: readonly HeadCell[] = [
        {
            id: 'userName',
            numeric: false,
            disablePadding: false,
            label: 'Username',
            type: 'string',
        },
        {
            id: 'fullName',
            numeric: false,
            disablePadding: false,
            label: 'Name',
            type: 'string',
        },
        {
            id: 'department',
            numeric: false,
            disablePadding: false,
            label: 'Department',
            type: 'string',
        },
        {
            id: 'isActive',
            numeric: false,
            disablePadding: false,
            label: 'Active',
            type: 'active',
        },
        {
            id: 'workStatus',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            type: 'status',
        }
    ];


    const renderFilters = () => (
      <Fragment>
        <FormControl size="sm">
            <FormLabel>Department</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                disabled
                // value={selectedHost}
                // disabled={!users}
                // onChange={(e, newValue) => setSelectedHost(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {/* {users && users.map((item, index) => <Option key={index} value={item.fullName}>{item.fullName}</Option>)} */}
            </Select>
        </FormControl>
        <FormControl size="sm">
            <FormLabel>Status</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                disabled
                // value={selectedStatus}
                // disabled={!stores}
                // onChange={(e, newValue) => setSelectedStatus(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {/* {statuses.map((x:string) => <Option key={x} value={x}>{capitalizeFirstLetter(x.replaceAll('-',' ').replaceAll('_',' '))}</Option>)} */}
            </Select>
        </FormControl>
      </Fragment>
    );

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Users',
            url: '/users',
            current: true
        },
    ];

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >                
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1568992687947-868a62a9f521?auto=format&w=1000&dpr=2'}
                    title={'Users'}
                >
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => fetchUserProfiles()}
                    >
                        <ReplayOutlinedIcon />
                    </Button>
                    <Button
                        color="primary"
                        startDecorator={<AddIcon />}
                        size="sm"
                        disabled
                    >
                        Add user
                    </Button>
                </AppHeader>

                <Box
                    className="SearchAndFilters-tabletUp"
                    sx={{
                    buserRadius: 'sm',
                    py: 2,
                    display: { xs: 'none', sm: 'flex' },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: { xs: '120px', md: '160px' },
                    },
                    }}
                >
                    <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for user</FormLabel>
                    <Input value={searchByName} onChange={e => setSearchByName(e.target.value)} size="sm" variant='soft' placeholder="Search" startDecorator={<SearchIcon />} />
                    </FormControl>
                    {renderFilters()}
                </Box>
                
                <AceGrid
                    headers={headCells}
                    data={tmpUsers}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                    deleteList={setDeleteList}
                    defaultSortBy={'visit_date'}
                    defaultSort={'desc'}
                    defaultId={'userId'}
                />
                
                <UsersList
                    users={users}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                />
            </Box>

            <Modal open={openCreateUpdateModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                setModalUser(null);
                setOpenCreateUpdateModal(false);
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                <ModalClose />
                <DialogTitle>User</DialogTitle>
                {modalLoading && <LinearProgress
                    color="primary"
                    determinate={false}
                    size="sm"
                    variant="soft"
                    />}
                    {modalUser && <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                        modalUserStep < 2 ? 
                        setModalUserStep(modalUserStep + 1) :
                        updateUserProfile(modalUser.userId, modalUser);
                    }}
                    >
                    <DialogContent>Insert user information</DialogContent>
                    <Stepper sx={{ width: '100%', margin: '15px 0px' }}>
                    {steps.map((step, index) => (
                        <Step
                        key={index}
                        indicator={
                            <StepIndicator
                            variant={modalUserStep <= index ? 'soft' : 'solid'}
                            color={modalUserStep < index ? 'neutral' : 'primary'}
                            >
                            {modalUserStep <= index ? index + 1 : <Check />}
                            </StepIndicator>
                        }
                        sx={{
                            '&::after': {
                            ...(modalUserStep > index &&
                                index !== 2 && { bgcolor: 'primary.solidBg' }),
                            },
                        }}
                        >
                        <StepButton onClick={() => index < modalUserStep && setModalUserStep(index)}>{step}</StepButton>
                        </Step>
                    ))}
                    </Stepper>
                    {modalUserStep === 0 &&
                    <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>First name</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.personalInformation?.firstName}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            personalInformation: {
                            ...modalUser.personalInformation,
                            firstName: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Last name</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.personalInformation?.lastName}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            personalInformation: {
                            ...modalUser.personalInformation,
                            lastName: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Work status</FormLabel>
                        <Select
                        placeholder="Select status"
                        size="sm"
                        variant="soft"
                        value={modalUser.workStatus}
                        onChange={(e, newValue) => setModalUser({
                            ...modalUser,
                            workStatus: newValue || ''
                        })}
                        >
                            <Option value="active">Active</Option>
                            <Option value="vacationing">Vacationing</Option>
                            <Option value="backwatch">Backwatch</Option>
                            <Option value="offwork">Off work</Option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input 
                        required 
                        type='email'
                        variant="soft" 
                        size="sm" 
                        disabled
                        value={modalUser.personalInformation?.email}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            personalInformation: {
                            ...modalUser.personalInformation,
                            email: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Country</FormLabel>
                        <Select
                        placeholder="Select country"
                        size="sm"
                        variant="soft"
                        value={modalUser.personalInformation?.phonePrefix}
                        onChange={(e, newValue) => setModalUser({
                            ...modalUser,
                            personalInformation: {
                            ...modalUser.personalInformation,
                            phonePrefix: newValue || ''
                            }
                        })}
                        >
                        {countries.map((item, index) => <Option key={index} value={item.phone}>
                            <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                            alt=""
                            />
                            {item.label}
                        </Option>)}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Phone</FormLabel>
                        <Input 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.personalInformation?.phoneNumber}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            personalInformation: {
                            ...modalUser.personalInformation,
                            phoneNumber: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <Button type="submit">Next</Button>
                    </Stack>}
                    
                    {modalUserStep === 1 &&
                    <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>City</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.userMainAddress?.city}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            userMainAddress: {
                            ...modalUser.userMainAddress,
                            city: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Province</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.userMainAddress?.province}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            userMainAddress: {
                            ...modalUser.userMainAddress,
                            province: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Post code</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.userMainAddress?.postCode}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            userMainAddress: {
                            ...modalUser.userMainAddress,
                            postCode: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Address 1</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.userMainAddress?.addressLine1}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            userMainAddress: {
                            ...modalUser.userMainAddress,
                            addressLine1: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Address 2</FormLabel>
                        <Input 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.userMainAddress?.addressLine2}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            userMainAddress: {
                            ...modalUser.userMainAddress,
                            addressLine2: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Address 3</FormLabel>
                        <Input 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.userMainAddress?.addressLine3}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            userMainAddress: {
                            ...modalUser.userMainAddress,
                            addressLine3: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <Button type="submit">Next</Button>
                    </Stack>}
                    
                    {modalUserStep === 2 &&
                    <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>Company</FormLabel>
                        <Input 
                        disabled 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.jobInformation?.companyName}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            jobInformation: {
                            ...modalUser.jobInformation,
                            companyName: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Department</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.jobInformation?.companyDepartment}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            jobInformation: {
                            ...modalUser.jobInformation,
                            companyDepartment: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Company phone</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.jobInformation?.companyPhone}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            jobInformation: {
                            ...modalUser.jobInformation,
                            companyPhone: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Office location</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={modalUser.jobInformation?.officeLocation}
                        onChange={(e) => setModalUser({
                            ...modalUser,
                            jobInformation: {
                            ...modalUser.jobInformation,
                            officeLocation: e.target.value
                            }
                        })}
                        />
                    </FormControl>
                    <Button type="submit">Save</Button>
                    </Stack>}
                    </form>}
                </ModalDialog>
            </Modal>

            <AppDeleteItemModal
                title={'Delete user'}
                description={'Are you sure you want to delete user?'}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                deleteFunction={deleteUserProfile}
            />
        </Layout>
    );
};

export default Users;