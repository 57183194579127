import React, {useState, useEffect, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import { Box,
    Button,
    Stack, 
    DialogTitle, 
    DialogContent, 
    LinearProgress, 
    Stepper, 
    Step, 
    StepButton, 
    StepIndicator, 
    FormControl, 
    FormLabel, 
    Input, 
    Modal, 
    ModalDialog, 
    ModalClose, 
    Select, 
    Option,
    Sheet,
    Typography,
    Divider,
    Tabs,
    TabList,
    Tab,
    TabPanel
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/joy/IconButton';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../../store/loadingSlice'
import { toast } from 'react-toastify';
import Layout from '../../../template/layout';

import {
    GetStoreGroupInvoices,
    GetStoreGroupInvoice,
    CreateStoreGroupInvoice,
    GetStoreGroups,
    StoreGroupInvoice,
    StoreGroup
} from '../../../api/storeGroups';

import { GetAllStores, Store } from '../../../api/stores';

import AppDeleteItemModal from '../../../components/AppDeleteItemModal';
import AceGrid, { HeadCell } from '../../../components/AceGrid';
import AppHeader from '../../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../../components/AppBreadcrumb';
import uuid from '../../../res/uuid';
import StoreGroupInvoicesList from './storeGroupInvoicesList';
import dayjs from 'dayjs';

const StoreGroupInvoices = () => {

    const navigate = useNavigate();
    const [storeGroupInvoices, setStoreGroupInvoices] = useState<StoreGroupInvoice[]>([]);
    const [tmpStoreGroupInvoices, setTmpStoreGroupInvoices] = useState<StoreGroupInvoice[]>(storeGroupInvoices);
    const [stores, setStores] = useState<Store[]>([]);
    const [openStoreGroupsModal, setOpenStoreGroupsModal] = useState(false);  
    const [modalStoreGroupInvoice, setModalStoreGroupInvoice] = useState<StoreGroupInvoice|null>(null); 
    const [modalLoading, setModalLoading] = useState(false);
    const [editId, setEditId] = useState<string | null>(null); 
    const [deleteId, setDeleteId] = useState<string | null>(null); 
    const [searchByName, setSearchByName] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);  

    const organization_id = localStorage.getItem('organization_id') || "";
    const store_group_id = localStorage.getItem('store_group_id') || "";
    const store_group_name = localStorage.getItem('store_group_name') || "";

    const dispatch = useDispatch();
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };
  
    const fetchStoreGroupInvoices = async () => {
        setLoading(true);
        setStoreGroupInvoices([]);
        GetStoreGroupInvoices(store_group_id, organization_id)
            .then(storeGroupInvoices => {
                setStoreGroupInvoices(storeGroupInvoices);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchStoreGroupInvoice = async (edge_id: string) => {
        if(edge_id){
            setOpenStoreGroupsModal(true);
            setModalLoading(true);
            organization_id && GetStoreGroupInvoice(edge_id, store_group_id, organization_id)
                .then(storeGroupInvoice => {
                    setModalStoreGroupInvoice(storeGroupInvoice);
                    setModalLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };

    const createStoreGroupInvoice = async () => {
        if(store_group_id){
            setOpenStoreGroupsModal(true);
            setModalLoading(true);
            organization_id && CreateStoreGroupInvoice(store_group_id, organization_id)
                .then(storeGroupInvoice => {
                    fetchStoreGroupInvoices();
                    setModalStoreGroupInvoice(storeGroupInvoice);
                    setModalLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };

    // const deleteStoreGroupInvoice = async (edge_id: string) => {
    //     if(edge_id){
    //         setLoading(true);            
    //         DeleteStoreGroupInvoice(edge_id, store_group_id, organization_id)
    //             .then(storeGroupInvoice => {
    //                 fetchStoreGroupInvoices();
    //                 setOpenStoreGroupsModal(false);
    //                 setDeleteId(null);
    //             })
    //             .catch(error => {
    //                 console.log(error);
                    
    //                 toast.error(error.message);
    //                 setLoading(false);
    //             });
    //     }
    // };
  
    const fetchStores = async () => {
        setLoading(true);
        organization_id && GetAllStores(organization_id)
            .then(stores => {
                setStores(stores);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };
  
    // const updateStore = async () => {
    //     try {
    //         // Use the same form to Create or Update Store Group Invoice
    //         setLoading(true);
    //         if(editId){
    //             UpdateStoreGroupInvoice(editId, store_group_id, organization_id, modalStoreGroupInvoice)
    //                 .then(storeGroupInvoice => {
    //                     fetchStoreGroupInvoices();
    //                     setOpenStoreGroupsModal(false);
    //                     setEditId(null);
    //                 })
    //                 .catch(error => {
    //                     toast.error(error.message);
    //                     setLoading(false);
    //                 });
    //         } else {
    //             CreateStoreGroupInvoice(store_group_id, organization_id, modalStoreGroupInvoice)
    //                 .then(storeGroupInvoice => {
    //                     fetchStoreGroupInvoices();
    //                     setOpenStoreGroupsModal(false);     
    //                     setModalStoreGroupInvoice(null);
    //                 })
    //                 .catch(error => {
    //                     toast.error(error.message);
    //                     setLoading(false);
    //                 });
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const newStoreGroupInvoice = () => {  
        // setEditId(null);
        // setDeleteId(null);
        setModalLoading(true);
        setModalStoreGroupInvoice(null);
        setOpenStoreGroupsModal(true);
        createStoreGroupInvoice();
    };
  
    useEffect(() => {
      fetchStores();
    },[]);
  
    useEffect(() => {      
        editId && fetchStoreGroupInvoice(editId);      
    },[editId]);
    
    useEffect(() => {
        stores && fetchStoreGroupInvoices();
    }, [stores]);
    
    useEffect(() => {
        setTmpStoreGroupInvoices(storeGroupInvoices);
    }, [storeGroupInvoices]);

    useEffect(() => {
        setTmpStoreGroupInvoices(storeGroupInvoices.filter((item:StoreGroupInvoice) => 
            (item?.invoiceId.toLowerCase().includes(searchByName.toLowerCase())
        )));
    }, [searchByName]);
    
    const getStoreName = (id:string) => {
        return stores ? (stores.find((item:Store) => item.id === id)?.name || '') : '';
    };
    
    const setDeleteList = (data:string[]) => {
        // console.log("Delete List: ", data);  
        toast.warning("We need to update API!");
    };

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Store groups',
            url: '/store-groups',
            current: false
        },
        {
            title: 'Store group invoices',
            url: '/store-group-invoices',
            current: true
        },
    ];

    const headCells: readonly HeadCell[] = [
        {
            id: 'invoiceId',
            numeric: false,
            disablePadding: false,
            label: 'Invoice ID',
            type: 'string',
        },
        {
            id: 'invoiceDate',
            numeric: false,
            disablePadding: false,
            label: 'Date',
            type: 'date',
        },
        {
            id: 'invoiceDueDate',
            numeric: false,
            disablePadding: false,
            label: 'Due Date',
            type: 'date',
        },
        {
            id: 'amount',
            numeric: true,
            disablePadding: false,
            label: 'Amount',
            type: 'string',
        },
    ];


    const renderFilters = () => (
      <Fragment>
      </Fragment>
    );

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >                
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1627997394689-e1c6343c91bb?auto=format&w=1000&dpr=2'}
                    title={`${store_group_name}: Invoices`}
                >
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => fetchStoreGroupInvoices()}
                    >
                        <ReplayOutlinedIcon />
                    </Button>
                    <Button
                        color="primary"
                        startDecorator={<AddIcon />}
                        size="sm"
                        onClick={() => newStoreGroupInvoice()}
                    >
                        Add Invoice
                    </Button>
                </AppHeader>

                {/* Filters - Mobile    */}
                <Sheet
                    className="SearchAndFilters-mobile"
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        my: 1,
                        gap: 1,
                    }}
                >
                    <Input
                        size="sm"
                        variant='soft'
                        placeholder="Search"
                        value={searchByName} onChange={e => setSearchByName(e.target.value)}
                        startDecorator={<SearchIcon />}
                        sx={{ flexGrow: 1 }}
                    />
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => setFiltersOpen(true)}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
                        <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderFilters()}
                            <Button color="primary" onClick={() => setFiltersOpen(false)}>
                            Submit
                            </Button>
                        </Sheet>
                        </ModalDialog>
                    </Modal>
                </Sheet>

                {/* Filters- Web */}

                <Box
                    className="SearchAndFilters-tabletUp"
                    sx={{
                    buserRadius: 'sm',
                    py: 2,
                    display: { xs: 'none', sm: 'flex' },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: { xs: '120px', md: '160px' },
                    },
                    }}
                >
                    <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for stores</FormLabel>
                    <Input value={searchByName} onChange={e => setSearchByName(e.target.value)} size="sm" variant='soft' placeholder="Search" startDecorator={<SearchIcon />} />
                    </FormControl>
                    {renderFilters()}
                </Box>

                <AceGrid
                    headers={headCells}
                    data={tmpStoreGroupInvoices}
                    defaultSortBy={'invoiceId'}
                    defaultSort={'asc'}
                    defaultId={'invoiceId'}
                />

                <StoreGroupInvoicesList storeGroupInvoices={tmpStoreGroupInvoices} setEditId={setEditId} setDeleteId={setDeleteId} stores={stores}/>
            </Box>
            <Modal open={openStoreGroupsModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setModalStoreGroupInvoice(null);
                    setOpenStoreGroupsModal(false);
                    setEditId(null);  
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                <ModalClose />
                <DialogTitle>Invoice</DialogTitle>
                {modalLoading ? <LinearProgress
                    color="primary"
                    determinate={false}
                    size="sm"
                    variant="soft"
                    /> : 
                    <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        setModalStoreGroupInvoice(null);
                        setOpenStoreGroupsModal(false);
                    }}
                    >
                    <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>Invoice ID</FormLabel>
                        <Input 
                        disabled 
                        variant="soft" 
                        size="sm" 
                        value={modalStoreGroupInvoice?.invoiceId}
                        />
                    </FormControl> 
                    <FormControl>
                        <FormLabel>Date</FormLabel>
                        <Input 
                        disabled 
                        variant="soft" 
                        size="sm" 
                        type="date"
                        value={dayjs(modalStoreGroupInvoice?.invoiceDate).format('YYYY-MM-DD')}
                        />
                    </FormControl>  
                    <FormControl>
                        <FormLabel>Due date</FormLabel>
                        <Input 
                        disabled 
                        variant="soft" 
                        size="sm" 
                        type="date"
                        value={dayjs(modalStoreGroupInvoice?.invoiceDueDate).format('YYYY-MM-DD')}
                        />
                    </FormControl>  
                    <FormControl>
                        <FormLabel>Amount</FormLabel>
                        <Input 
                        disabled 
                        variant="soft" 
                        size="sm" 
                        value={modalStoreGroupInvoice?.amount}
                        />
                    </FormControl> 
                    <Button type="submit">OK</Button>
                    </Stack>
                    </form>
                    }
                </ModalDialog>
            </Modal>

            {/* <AppDeleteItemModal
                title={'Delete store'}
                description={'Are you sure you want to delete store?'}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                deleteFunction={deleteStoreGroupInvoice}
            /> */}
        </Layout>
    );
};

export default StoreGroupInvoices;