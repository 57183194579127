import * as React from 'react';
import LinearProgress from '@mui/joy/LinearProgress';

export default function LinearProgressThickness() {
  return <LinearProgress 
            thickness={1} 
            variant="soft"
            style={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1999 }} 
          />;
}
