import React, { FC, useState, useEffect } from "react";
import { Box, Card, CardContent, CardCover, Typography } from "@mui/joy";


export interface props {
    children?: any;
    background?: string;
    title?: string;
}

const AppHeader = ({ children, background, title }: props) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Card component="div" variant='plain' sx={{  flexGrow: 1 }} >
            <CardCover>
            <img
                className='banner'
                src={background}
                srcSet={background}
                loading="lazy"
                alt=""
            />
            </CardCover>
            <CardCover
                sx={(theme) => ({
                background: 'linear-gradient(to top, rgba(255,255,255,0.2), rgba(255,255,255,0.8)), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 200px)',
                [theme.getColorSchemeSelector('dark')]: {
                    background: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0.5)), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 200px)',
                },
                })}
            />
            <CardContent>
                <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'end' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
                >
                    <Typography
                        level="h2"
                        component="h1"
                        fontWeight="lg"
                        textColor="#fff"
                        mt={{ xs: 4, sm: 6 }}
                    >
                        {title}
                    </Typography>
                    
                    <Box
                    sx={{
                        display: 'flex',
                        mb: 1,
                        gap: 1,
                        flexDirection: 'row',
                        alignItems: { xs: 'center', sm: 'end' },
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                    }}
                    >{children}</Box>                    
                </Box>
            </CardContent>
        </Card>
        </Box>);
};
export default AppHeader;


// // Send from parent component

// <AppHeader
//     background={'https://images.unsplash.com/photo-1634482897361-7f327bb7d009?auto=format&w=1000&dpr=2'}
//     title={'Visitors'}
// >
//     <Button
//         color="primary"
//         startDecorator={<AddIcon />}
//         size="sm"
//         onClick={() => newVisitor()}
//     >
//         Add visitor
//     </Button>
// </AppHeader>