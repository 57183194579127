import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LoadingState {
  is_loading: boolean,
}

const initialState: LoadingState = {
  is_loading: false,
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    loading: (state, action: PayloadAction<boolean>) => {
      state.is_loading = action.payload;      
    },
  },
})

// Action creators are generated for each case reducer function
export const { loading } = loadingSlice.actions

export default loadingSlice.reducer