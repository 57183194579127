import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../store/loadingSlice'
import Layout from '../../template/layout';
import axios from 'axios';
import { Box, FormControl, FormLabel, Grid, Input, Select, Stack, Textarea, Typography, Option, Button } from '@mui/joy';

const Test = () => {
    const dispatch = useDispatch();

    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };

    const _url = "https://test.internal.acesolutions.no/Test/Test1";
    const _requestMethod = "GET";
    const _requestBody = '{ "id":"xxxxx" }';
    const _requestHeader = `{
            "Content-Type": "application/json",
            "Authorization": "Bearer ${localStorage.getItem('id_token')}",
            "Access-Control-Allow-Origin": "*"
        }`;
            
    const [url, setUrl] = useState<string>(_url);
    const [requestMethod, setRequestMethod] = useState<string>(_requestMethod);
    const [requestBody, setRequestBody] = useState<string>(_requestBody);
    const [requestHeader, setRequestHeader] = useState<string>(_requestHeader);
    const [response, setResponse] = useState<any | null>(null);

    const resetForm = () => {
        setUrl(_url);
        setRequestMethod(_requestMethod);
        setRequestBody(_requestBody);
        setRequestHeader(_requestHeader);
        setResponse(null);
    };

    const runTest = async () => {
        setLoading(true);
        axios({
            method: requestMethod,
            url: url,
            data: JSON.parse(requestBody),
            headers: JSON.parse(requestHeader),
            timeout: 3000,
        }).then((response) => {
            setResponse(response);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            setResponse(error);
        });
    };

    return (<Layout>
        <Box
            component="main"
            className="MainContent"
            sx={{
            px: { xs: 2, md: 6 },
            pt: {
                xs: 'calc(12px + var(--Header-height))',
                sm: 'calc(12px + var(--Header-height))',
                md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100dvh',
            gap: 1,
            }}
            style={{
            position: 'relative',
            maxWidth: '1400px',
            margin: '0 auto'
            }}
        >
        <Typography level="h1" fontSize="xl" sx={{ mb: 0.5 }}>
            Test API
        </Typography>
        <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{ flexGrow: 1 }}
        >
            <Grid xs={4} sm={6} md={6}>
                <Stack spacing={2}>
                    <FormControl>
                        <FormLabel>Method</FormLabel>
                        <Select
                        size="sm"
                        variant="soft"
                        value={requestMethod}
                        onChange={(e, newValue) => newValue && setRequestMethod(newValue)}
                        >
                            <Option value='GET'>GET</Option>
                            <Option value='POST'>POST</Option>
                            <Option value='PUT'>PUT</Option>
                            <Option value='DELETE'>DELETE</Option>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>URL</FormLabel>
                        <Input 
                        required 
                        variant="soft" 
                        size="sm" 
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Request header</FormLabel>
                        <Textarea
                          required
                          minRows={2}
                          maxRows={4}
                          size="sm" 
                          sx={{ mb: 1 }}
                          value={requestHeader}
                          onChange={(e) => setRequestHeader(e.target.value)}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Request body</FormLabel>
                        <Textarea
                          required
                          minRows={5}
                          maxRows={5}
                          size="sm" 
                          sx={{ mb: 1 }}
                          value={requestBody}
                          onChange={(e) => setRequestBody(e.target.value)}
                        />
                    </FormControl>
                    <Stack direction={'row'} spacing={2}>
                            <Button
                                color="neutral"
                                size="sm"
                                onClick={() => resetForm()}
                            >
                                Reset
                            </Button>
                            <Button
                                color="primary"
                                size="sm"
                                onClick={() => runTest()}
                            >
                                Test API request
                            </Button>
                    </Stack>
                    {response && <FormControl>
                        <FormLabel>Response</FormLabel>
                        <Textarea
                          required
                          minRows={5}
                          maxRows={10}
                          size="sm" 
                          sx={{ mb: 1 }}
                          value={JSON.stringify(response)}
                        />
                    </FormControl>}
                </Stack>
            </Grid>
        </Grid>
    </Box>
</Layout>)};

export default Test;