import axios from "axios";
import headers from "./headers";
import redirect from "./redirect";

// https://func-usermanagement-api-prod.azurewebsites.net/api/swagger/ui#/

export interface Message {
    message: string,
    status: string,
    visitor_id: string
}

export interface Statistics {
    total_visitors: number,
    visitor_statistics: {
        checked_in: number,
        checked_out: number,
        pre_registered: number,
    }
}

export interface ResVisitors {
    status: string,
    visitors: VisitorProfile[]
}

export interface Visitor {
    purpose: string,
    status?: string,
    visit_date: string,
    visitor_id?: string,
    visitor_name: string,
    tenant_id?: string,
}

export interface VisitorProfile {
    id?: string,
    tenant_id: string,
    visitor_name: string,
    visitor_phone?: string,
    visitor_upn?: string,
    host_name: string,
    host_phone?: string | null,
    host_upn?: string,
    visit_date: string,
    purpose: string,
    store_id: string,
    status?: string,
    check_in_code: string,
    events?: [
        {
            type: string,
            timestamp: string,
            device_id: string,
        }
    ],
    _rid?: string,
    _self?: string,
    _etag?: string,
    _attachments?: string,
    ics?: string,
    ics_format?: string,
    store?: string,
    _ts?: string
}

export const GetAllVisitors = async (org_id:string): Promise<ResVisitors> => {
    try {
        const { data } = await axios.get<ResVisitors>(`${process.env.REACT_APP_VISITATION_API}/api/v1/management/visitors?tenant_id=${org_id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch visitors:', error);
        return {status:'error', visitors:[]};
    }
}

export const CreateVisitor = async (visitor: Visitor): Promise<Message | null> => {
    try {
        const { data } = await axios.post<Message>(`${process.env.REACT_APP_VISITATION_API}/api/v1/management/visitors`, visitor, {
            headers: headers(localStorage.getItem('id_token'))
        });     
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to create user:', error);
        return null;
    }
}

export const UpdateVisitor = async (visitor_id:string, org_id: string, visitor: Visitor): Promise<Message | null> => {
    try {
        const { data } = await axios.patch<Message>(`${process.env.REACT_APP_VISITATION_API}/api/v1/management/visitors/${visitor_id}?tenant_id=${org_id}`, visitor, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update user:', error);
        return null;
    }
}

export const DeleteVisitor = async (visitor_id:string, org_id: string): Promise<Message | null> => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_VISITATION_API}/api/v1/management/visitors/${visitor_id}?tenant_id=${org_id}`, {
            headers: headers(localStorage.getItem('id_token')),
            data: {}
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to delete user:', error);
        return null;
    }
}

export const GetVisitorsStatistics = async (org_id:string): Promise<Statistics | null> => {
    try {
        const { data } = await axios.get<Statistics>(`${process.env.REACT_APP_VISITATION_API}/api/v1/management/visitors/statistics?tenant_id=${org_id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });      
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch visitors statistics:', error);
        return null;
    }
}