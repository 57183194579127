import * as React from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import { useNavigate } from "react-router-dom";
import Add from '@mui/icons-material/Add';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import LocalConvenienceStoreOutlinedIcon from '@mui/icons-material/LocalConvenienceStoreOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import DevicesOtherOutlinedIcon from '@mui/icons-material/DevicesOtherOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import BroadcastOnPersonalOutlinedIcon from '@mui/icons-material/BroadcastOnPersonalOutlined';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

import { AspectRatio } from '@mui/joy';

export default function MainWidget(props:any) {
    const {status, title, description = null, url, icon = null, image = null, children} = props;
    const navigate = useNavigate();

    const insertIcon = (val:string) => {
        switch(val){
            case 'organizations': return <AccountBalanceOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'stores': return <StoreMallDirectoryOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'store-groups': return <LocalConvenienceStoreOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'users': return <GroupRoundedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'visitors': return <BadgeOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'devices': return <DevicesOtherOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'zones': return <BroadcastOnPersonalOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'satisfactions': return <SentimentVerySatisfiedOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'tickets': return <LocalActivityOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'tasks': return <AssignmentTurnedInOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            case 'cash': return <PaymentsOutlinedIcon style={{fontSize: '2rem', marginTop: '0.3rem'}} />;
            default: return '';
        }
    };

    return (
        <Card
            variant="solid"
            color={status}
            sx={{
                height: '100%',
                backgroundImage: image ? `url(https://images.unsplash.com/${image}?auto=format&w=500&dpr=2)` : null,
                backgroundSize: image ? 'cover' : null,
                padding: '0px',
                userSelect: 'none'
            }} invertedColors>
            <AspectRatio
                variant="plain"
                color={status}
                ratio="8/7"
                sx={{ width: "100%", backgroundColor: 'rgba(41, 115, 212, 0.9)', padding: '1rem' }}
            ><div style={{ display: 'flex', flexDirection: 'column', justifyContent:'space-between', width: '100%', height: '100%', gap: '0.5rem'}}>
                <CardContent orientation="horizontal" sx={{ width: '100%', gap: '0.5rem', flex: 0}} >
                    {icon && insertIcon(icon)}
                    <CardContent>
                        <Typography level="h2" className='single-line'>{title}</Typography>
                    </CardContent>
                </CardContent>
                {children && children}
                {description && <Typography level="body-md">{description}</Typography>}
                <CardActions sx={{ width: '100%', justifyContent: 'space-between', gap: '0.5rem'}}>
                    <Button startDecorator={<Add />} variant="soft" size="sm" className='single-line' style={{flex: 1}}  onClick={() => navigate(url)}>
                        Add
                    </Button>
                    <Button variant="solid" size="sm" className='single-line' style={{flex: 1}} onClick={() => navigate(url)}>
                        {title}
                    </Button>
                </CardActions>
                </div>
            </AspectRatio>
        </Card>
    );
}
