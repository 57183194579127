import axios from "axios";
import headers from "./headers";
import redirect from "./redirect";

// https://func-usermanagement-api-prod.azurewebsites.net/api/swagger/ui#/

export interface ApiError {
    message?: string
    status?: number
}

export interface User {
    userId: string,
    fullName: string,
    userName: string,
    department: string,
    personalPhoneNumber?: string,
    companyPhoneNumber?: string,
    email: string,
    isActive: boolean
}

export interface UserProfile {
    userId: string,
    workStatus: string,
    personalInformation: {
        prefix: string,
        firstName: string,
        lastName: string,
        email: string,
        phonePrefix: string,
        phoneNumber: string,
        languageIso: string,
        location: string,
        timeZone: string,
        onlinePresence: string
    },
    userMainAddress: {
        countryIso: string,
        city: string,
        province: string,
        postCode: string,
        addressLine1: string,
        addressLine2: string,
        addressLine3: string
    },
    jobInformation: {
        companyName: string,
        companyDepartment: string,
        companyPhone: string,
        officeLocation: string
    },
    email: string | null,
}

export const GetAllUserProfiles = async (): Promise<User[]> => {
    try {
        const { data } = await axios.get<User[]>(`${process.env.REACT_APP_USER_API}/api/organizations/${localStorage.getItem('organization_id')}/user-profiles`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;  
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch users:', error);
        return [];
    }
}

export const GetUserProfile = async (id: string): Promise<UserProfile | null> => {
    try {
        const { data } = await axios.get<UserProfile>(`${process.env.REACT_APP_USER_API}/api/user-profiles/${id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch user:', error);
        return null;
    }
}

export const CreateUserProfile = async (user: UserProfile): Promise<UserProfile | null> => {
    try {
        const { data } = await axios.post<UserProfile>(`${process.env.REACT_APP_USER_API}/api/user-profiles`, user, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to create user:', error);
        return null;
    }
}

export const UpdateUserProfile = async (id: string, user: UserProfile): Promise<UserProfile | null> => {
    try {
        const { data } = await axios.put<UserProfile>(`${process.env.REACT_APP_USER_API}/api/user-profiles/${id}`, user, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update user:', error);
        return null;
    }
}

export const DeleteUserProfile = async (id: string) => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_USER_API}/api/user-profiles/${id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to delete user:', error);
        return null;
    }
}