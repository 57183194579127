import React from 'react';
import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Dashboard from './pages/dashboard';
import Users from './pages/users';
import Visitors from './pages/visitors';
import User from './pages/users/user';
import Test from './pages/test';
import Organizations from './pages/organization/organizations';
import Stores from './pages/organization/stores';
import StoreGroups from './pages/organization/store-groups';
import StoreGroupEdges from './pages/organization/store-group-edges';
import StoreGroupInvoices from './pages/organization/store-group-invoices';
import Devices from './pages/devices';
import { store } from './store';
import { Provider } from 'react-redux';
import Zones from './pages/zones';
import Satisfactions from './pages/satisfactions';
import DeviceTypes from './pages/device-types';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Dashboard />}  />
        <Route path="/users" element={<Users />}  />
        <Route path="/user" element={<User />}  />
        <Route path="/visitors" element={<Visitors />}  />
        <Route path="/organizations" element={<Organizations />}  />
        <Route path="/stores" element={<Stores />}  />
        <Route path="/test" element={<Test />}  />
        <Route path="/store-groups" element={<StoreGroups />}  />
        <Route path="/store-group-edges" element={<StoreGroupEdges />}  />
        <Route path="/store-group-invoices" element={<StoreGroupInvoices />}  />
        <Route path="/devices" element={<Devices />}  />
        <Route path="/device-types" element={<DeviceTypes />}  />
        <Route path="/zones" element={<Zones />}  />
        <Route path="/satisfactions" element={<Satisfactions />}  />
      </Routes>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
