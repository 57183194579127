import React, {useState, useEffect, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import { Box,
    Button,
    Stack, 
    DialogTitle, 
    DialogContent, 
    LinearProgress, 
    FormControl, 
    FormLabel, 
    Input, 
    Modal, 
    ModalDialog, 
    ModalClose, 
    Sheet,
    Typography,
    Divider,
    Tabs,
    TabPanel,
    TabList,
    Tab,
    Switch,
    List,
    ListItem,
    ListItemDecorator,
    ListDivider,
    AccordionGroup,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItemButton,
    IconButton,
    Select,
    Option,
    ModalOverflow
} from '@mui/joy';
import { switchClasses } from '@mui/joy/Switch';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import NoMeetingRoomOutlinedIcon from '@mui/icons-material/NoMeetingRoomOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../../store/loadingSlice'
import { toast } from 'react-toastify';
import Layout from '../../../template/layout';

import { GetAllStores, GetStore, CreateStore, UpdateStore, DeleteStore, Store } from '../../../api/stores';

import AppDeleteItemModal from '../../../components/AppDeleteItemModal';
import AceGrid, { HeadCell } from '../../../components/AceGrid';
import AppHeader from '../../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../../components/AppBreadcrumb';
import uuid from '../../../res/uuid';
import StoresList from './storesList';
import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { ChangeDateTime } from '../../../res/datetime';

const Stores = () => {

    const defaultStore:Store = {
        id: uuid(),
        organizationId: localStorage.getItem('organization_id') || "",
        name: "",
        address: "",
        isAutomaticStore: false,
        isOpen: false,
        workingHours: {
            "regularHours": {
                "monday": {
                    "openUtc": "08:00:00",
                    "closeUtc": "17:00:00"
                },
                "tuesday": {
                    "openUtc": "08:00:00",
                    "closeUtc": "17:00:00"
                },
                "wednesday": {
                    "openUtc": "08:00:00",
                    "closeUtc": "17:00:00"
                },
                "thursday": {
                    "openUtc": "08:00:00",
                    "closeUtc": "17:00:00"
                },
                "friday": {
                    "openUtc": "08:00:00",
                    "closeUtc": "17:00:00"
                },
                "saturday": {
                    "openUtc": "10:00:00",
                    "closeUtc": "14:00:00"
                },
                "sunday": {
                    "openUtc": null,
                    "closeUtc": null
                }
            },
            "specialHours": {},
            "holidays": {}
        }
    };

    const defaultSpecialDay:any = {
        date:dayjs(new Date()).format(),
        isClosed: null,
        openUtc: dayjs(new Date()).hour(8).minute(0).format(),
        closeUtc: dayjs(new Date()).hour(15).minute(0).format()
    };

    const defaultHolidayDay:any = {
        date:dayjs(new Date()).format(),
        holidayWorkStatus: 0,
        name: ''
    };

    const navigate = useNavigate();
    const [stores, setStores] = useState<Store[]>([]);
    const [tmpStores, setTmpStores] = useState<Store[]>(stores);
    const [openStoreModal, setOpenStoreModal] = useState(false);  
    const [modalStore, setModalStore] = useState<Store>(defaultStore); 
    const [modalLoading, setModalLoading] = useState(false);
    const [editId, setEditId] = useState<string | null>(null); 
    const [deleteId, setDeleteId] = useState<string | null>(null); 
    const [searchByName, setSearchByName] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);

    const [index, setIndex] = React.useState<number | null>(null);
    const [specialDay, setSpecialDay] = React.useState<any>(defaultSpecialDay);
    const [tmpSpecialDay, setTmpSpecialDay] = React.useState<any>(defaultSpecialDay);
    const [holidayDay, setHolidayDay] = React.useState<any>(defaultHolidayDay);
    const [tmpHolidayDay, setTmpHolidayDay] = React.useState<any>(defaultHolidayDay);

    const organization_id = localStorage.getItem('organization_id');

    const dispatch = useDispatch();
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };
  
    const fetchStores = async () => {
        setLoading(true);
        setStores([]);
        organization_id && GetAllStores(organization_id)
            .then(stores => {
                setStores(stores);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchStore = async (id: string) => {
        if(id){            
            setOpenStoreModal(true);
            setModalLoading(true);
            setIndex(null);
            organization_id && GetStore(id, organization_id)
                .then(user => {
                    if(!user.workingHours) user = {...user, workingHours: defaultStore.workingHours };
                    setModalStore(user);
                    setModalLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };

    const deleteStore = async (id: string) => {
        if(id){
            setLoading(true);  
            setStores([]);          
            DeleteStore(id, localStorage.getItem('organization_id') || '')
                .then(user => {
                    fetchStores();
                    setOpenStoreModal(false);
                    setDeleteId(null);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };
  
    const updateStore = async () => {
        // Use the same form to Create or Update Organization
        setLoading(true);
        if(editId){
            UpdateStore(modalStore.id, modalStore)
                .then(store => {
                    fetchStores();
                    setOpenStoreModal(false);
                    setModalStore(defaultStore);
                    setSpecialDay(defaultSpecialDay);
                    setTmpSpecialDay(defaultSpecialDay);
                    setTmpHolidayDay(defaultHolidayDay);
                    setEditId(null);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        } else {
            CreateStore(modalStore)
                .then(user => {
                    fetchStores();
                    setOpenStoreModal(false);     
                    setModalStore(defaultStore);
                    setSpecialDay(defaultSpecialDay);
                    setTmpSpecialDay(defaultSpecialDay);
                    setTmpHolidayDay(defaultHolidayDay);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };

    const newStore = () => {  
        setEditId(null);
        setDeleteId(null);      
        setModalStore(defaultStore);
        setSpecialDay(defaultSpecialDay);
        setTmpSpecialDay(defaultSpecialDay);
        setTmpHolidayDay(defaultHolidayDay);
        setOpenStoreModal(true);
    };
  
    useEffect(() => {
      fetchStores();
    },[]);
  
    useEffect(() => {      
        editId && fetchStore(editId);      
    },[editId]);
    
    useEffect(() => {
        setTmpStores(stores);
    }, [stores]);

    useEffect(() => {
        setTmpStores(stores.filter((item:Store) => 
            (item?.name.toLowerCase()).includes(searchByName.toLowerCase()
        )));
    }, [searchByName]);

    const capitalizeFirstLetter = (txt:string) => {
        return txt.charAt(0).toUpperCase() + txt.slice(1)
    }
    
    const setDeleteList = (data:string[]) => {
        // console.log("Delete List: ", data);  
        toast.warning("We need to update API!");
    };

    const setRegularWorkingDay = (day:string, openOrClose:string, value:string|null) => {
        setModalStore({
            ...modalStore,
            workingHours: {
                ...modalStore.workingHours,
                regularHours: {
                    ...modalStore.workingHours.regularHours,
                    [day]: {
                        ...modalStore.workingHours.regularHours[day],
                        [openOrClose]: value
                    }
                }
            }
        });
    };

    const addSpecialDay = () => {
        setModalStore({
            ...modalStore,
            workingHours: {
                ...modalStore.workingHours,
                specialHours: {
                    ...modalStore.workingHours.specialHours,
                    [dayjs(tmpSpecialDay.date).format('YYYY-MM-DD')]: {
                        ...modalStore.workingHours.specialHours[dayjs(tmpSpecialDay.date).format('YYYY-MM-DD')],
                        isClosed: tmpSpecialDay.isClosed,
                        closeUtc: dayjs(tmpSpecialDay.closeUtc).format('HH:mm:ss'),
                        openUtc: dayjs(tmpSpecialDay.openUtc).format('HH:mm:ss')
                    }
                }
            }
        });
        setSpecialDay(defaultSpecialDay);
    };

    const deleteSpecialDay = (date:string) => {
        setModalStore({
            ...modalStore,
            workingHours: {
                ...modalStore.workingHours,
                specialHours: {...Object.fromEntries(Object.entries(modalStore.workingHours.specialHours).filter(([key]) => key !== date))}
            }
        });
    };

    const addHolidayDay = () => {
        setModalStore({
            ...modalStore,
            workingHours: {
                ...modalStore.workingHours,
                holidays: {
                    ...modalStore.workingHours.holidays,
                    [dayjs(tmpHolidayDay.date).format('YYYY-MM-DD')]: {
                        ...modalStore.workingHours.holidays[dayjs(tmpHolidayDay.date).format('YYYY-MM-DD')],
                        holidayWorkStatus: tmpHolidayDay.holidayWorkStatus,
                        name: tmpHolidayDay.name
                    }
                }
            }
        });
        setHolidayDay(defaultHolidayDay);
    };

    const deleteHolidayDay = (date:string) => {
        setModalStore({
            ...modalStore,
            workingHours: {
                ...modalStore.workingHours,
                holidays: {...Object.fromEntries(Object.entries(modalStore.workingHours.holidays).filter(([key]) => key !== date))}
            }
        });
    };

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Stores',
            url: '/stores',
            current: true
        },
    ];

    const headCells: readonly HeadCell[] = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
            type: 'string',
        },
        {
            id: 'isOpen',
            numeric: false,
            disablePadding: false,
            label: 'Open',
            type: 'toggle',
        },
    ];


    const renderFilters = () => (
      <Fragment>
      </Fragment>
    );

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >                
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1655522060985-6769176edff7?auto=format&w=1000&dpr=2'}
                    title={'Stores'}
                >
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => fetchStores()}
                    >
                        <ReplayOutlinedIcon />
                    </Button>
                    <Button
                        color="primary"
                        startDecorator={<AddIcon />}
                        size="sm"
                        onClick={() => newStore()}
                    >
                        Add Store
                    </Button>
                </AppHeader>

                {/* Filters - Mobile    */}
                <Sheet
                    className="SearchAndFilters-mobile"
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        my: 1,
                        gap: 1,
                    }}
                >
                    <Input
                        size="sm"
                        variant='soft'
                        placeholder="Search"
                        value={searchByName} onChange={e => setSearchByName(e.target.value)}
                        startDecorator={<SearchIcon />}
                        sx={{ flexGrow: 1 }}
                    />
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => setFiltersOpen(true)}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
                        <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderFilters()}
                            <Button color="primary" onClick={() => setFiltersOpen(false)}>
                            Submit
                            </Button>
                        </Sheet>
                        </ModalDialog>
                    </Modal>
                </Sheet>

                {/* Filters- Web */}

                <Box
                    className="SearchAndFilters-tabletUp"
                    sx={{
                    buserRadius: 'sm',
                    py: 2,
                    display: { xs: 'none', sm: 'flex' },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: { xs: '120px', md: '160px' },
                    },
                    }}
                >
                    <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for store</FormLabel>
                    <Input value={searchByName} onChange={e => setSearchByName(e.target.value)} size="sm" variant='soft' placeholder="Search" startDecorator={<SearchIcon />} />
                    </FormControl>
                    {renderFilters()}
                </Box>

                <AceGrid
                    headers={headCells}
                    data={tmpStores}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                    deleteList={setDeleteList}
                    defaultSortBy={'name'}
                    defaultSort={'desc'}
                    defaultId={'id'}
                />
                
                <StoresList
                    stores={tmpStores}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                /> 

            </Box>
            
            <Modal open={openStoreModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setModalStore(defaultStore);
                    setSpecialDay(defaultSpecialDay);
                    setTmpHolidayDay(defaultHolidayDay);
                    setOpenStoreModal(false);
                    setEditId(null);
                    setDeleteId(null);    
                }
            }} >
                <ModalDialog layout='center' variant='plain' sx={{minWidth: '40%', maxWidth: '700px'}}>
                    <ModalClose />
                    <DialogTitle>Store</DialogTitle>
                    
                {modalLoading ? <LinearProgress
                    color="primary"
                    determinate={false}
                    size="sm"
                    variant="soft"
                    /> :
                        <ModalOverflow sx={{ position: 'relative', padding: '0px' }}>
                    <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                         event.preventDefault();
                        updateStore();
                    }}
                    >
                    <Stack spacing={2}>
                        <DialogContent>Insert store information</DialogContent>
                        <Tabs aria-label="Basic tabs" defaultValue={0}>
                            <TabList>
                                <Tab>Details</Tab>
                                <Tab>Working hours</Tab>
                            </TabList>
                            <TabPanel value={0}>
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalStore.name || ''}
                                        onChange={(e) => setModalStore({
                                            ...modalStore,
                                            name: e.target.value
                                            }
                                        )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Address</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalStore.address || ''}
                                        onChange={(e) => setModalStore({
                                            ...modalStore,
                                            address: e.target.value
                                            }
                                        )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <Stack
                                        direction="row"
                                        sx={{
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        >
                                            <span>Automatic store</span>
                                            <Switch
                                                checked={modalStore.isAutomaticStore}
                                                onChange={(event) => setModalStore({
                                                    ...modalStore,
                                                    isAutomaticStore: event.target.checked
                                                    }
                                                )}
                                                sx={(theme) => ({
                                                    [`& .${switchClasses.thumb}`]: {
                                                      transition: 'width 0.2s, left 0.2s',
                                                    },
                                                  })}
                                            />
                                            {/* {modalStore.isAutomaticStore ? <ToggleOnOutlinedIcon style={{fontSize: '3rem'}} color='success' /> : <ToggleOffOutlinedIcon style={{fontSize: '3rem'}} />} */}
                                        </Stack>
                                    </FormControl>
                                    <FormControl>
                                        <Stack
                                        direction="row"
                                        sx={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        // onClick={() => !modalStore.isAutomaticStore && setModalStore({
                                        //     ...modalStore,
                                        //     isOpen: !modalStore.isOpen
                                        //     }
                                        // )}
                                        >
                                            {modalStore.isOpen ? <MeetingRoomOutlinedIcon style={{fontSize: '3rem'}} color='success' /> : <NoMeetingRoomOutlinedIcon style={{fontSize: '3rem'}} />}
                                            <span>{modalStore.isOpen ? "Open" : "Closed" }</span>
                                        </Stack>
                                    </FormControl>
                                </Stack>
                            </TabPanel>
                            <TabPanel value={1}>
                                <Tabs aria-label="Basic tabs" orientation="vertical" size="sm" defaultValue={0}>
                                    <TabList>
                                        <Tab>Regular</Tab>
                                        <Tab>Special</Tab>
                                        <Tab>Holidays</Tab>
                                    </TabList>
                                    <TabPanel value={0}>
                                    <AccordionGroup sx={{ maxWidth: 400 }}>
                                        <Accordion
                                            expanded={index === 0}
                                            onChange={(event, expanded) => {
                                            setIndex(expanded ? 0 : null);
                                            }}
                                        >
                                            <AccordionSummary>Monday</AccordionSummary>
                                            <AccordionDetails>
                                                <Stack
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                }}
                                                >
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.monday?.openUtc || '08:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('monday', 'openUtc', e.target.value)}
                                                    />
                                                    <span>&nbsp;to&nbsp;</span>
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.monday?.closeUtc || '17:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('monday', 'closeUtc', e.target.value)}
                                                    />
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            expanded={index === 1}
                                            onChange={(event, expanded) => {
                                            setIndex(expanded ? 1 : null);
                                            }}
                                        >
                                            <AccordionSummary>Tuesday</AccordionSummary>
                                            <AccordionDetails>
                                                <Stack
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                }}
                                                >
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.tuesday?.openUtc || '08:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('tuesday', 'openUtc', e.target.value)}
                                                    />
                                                    <span>&nbsp;to&nbsp;</span>
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.tuesday?.closeUtc || '17:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('tuesday', 'closeUtc', e.target.value)}
                                                    />
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            expanded={index === 2}
                                            onChange={(event, expanded) => {
                                            setIndex(expanded ? 2 : null);
                                            }}
                                        >
                                            <AccordionSummary>Wednesday</AccordionSummary>
                                            <AccordionDetails>
                                                <Stack
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                }}
                                                >
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.wednesday?.openUtc || '08:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('wednesday', 'openUtc', e.target.value)}
                                                    />
                                                    <span>&nbsp;to&nbsp;</span>
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.wednesday?.closeUtc || '17:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('wednesday', 'closeUtc', e.target.value)}
                                                    />
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            expanded={index === 3}
                                            onChange={(event, expanded) => {
                                            setIndex(expanded ? 3 : null);
                                            }}
                                        >
                                            <AccordionSummary>Thursday</AccordionSummary>
                                            <AccordionDetails>
                                                <Stack
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                }}
                                                >
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.thursday?.openUtc || '08:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('thursday', 'openUtc', e.target.value)}
                                                    />
                                                    <span>&nbsp;to&nbsp;</span>
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.thursday?.closeUtc || '17:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('thursday', 'closeUtc', e.target.value)}
                                                    />
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            expanded={index === 4}
                                            onChange={(event, expanded) => {
                                            setIndex(expanded ? 4 : null);
                                            }}
                                        >
                                            <AccordionSummary>Friday</AccordionSummary>
                                            <AccordionDetails>
                                                <Stack
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                }}
                                                >
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.friday?.openUtc || '08:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('friday', 'openUtc', e.target.value)}
                                                    />
                                                    <span>&nbsp;to&nbsp;</span>
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.friday?.closeUtc || '17:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('friday', 'closeUtc', e.target.value)}
                                                    />
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            expanded={index === 5}
                                            onChange={(event, expanded) => {
                                            setIndex(expanded ? 5 : null);
                                            }}
                                        >
                                            <AccordionSummary>Saturday</AccordionSummary>
                                            <AccordionDetails>
                                                <Stack
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                }}
                                                >
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.saturday?.openUtc || '08:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('saturday', 'openUtc', e.target.value)}
                                                    />
                                                    <span>&nbsp;to&nbsp;</span>
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.saturday?.closeUtc || '17:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('saturday', 'closeUtc', e.target.value)}
                                                    />
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Accordion
                                            expanded={index === 6}
                                            onChange={(event, expanded) => {
                                            setIndex(expanded ? 6 : null);
                                            }}
                                        >
                                            <AccordionSummary>Sunday</AccordionSummary>
                                            <AccordionDetails>
                                                <Stack
                                                sx={{
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    flexDirection: { xs: 'column', sm: 'row' },
                                                }}
                                                >
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.sunday?.openUtc || '08:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('sunday', 'openUtc', e.target.value)}
                                                    />
                                                    <span>&nbsp;to&nbsp;</span>
                                                    <Input 
                                                        type='time'
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={modalStore?.workingHours?.regularHours?.sunday?.closeUtc || '17:00:00'}
                                                        onChange={(e) => setRegularWorkingDay('sunday', 'closeUtc', e.target.value)}
                                                    />
                                                </Stack>
                                            </AccordionDetails>
                                        </Accordion>
                                    </AccordionGroup>
                                    </TabPanel>
                                    <TabPanel value={1}>
                                        <AccordionGroup sx={{ maxWidth: 400 }}>
                                            {modalStore?.workingHours && Object.keys(modalStore?.workingHours?.specialHours).map((date) => (<Accordion
                                                expanded={specialDay.date === date}
                                                onChange={(event, expanded) => {
                                                setSpecialDay({...specialDay, date: expanded ? date : null});
                                                }}
                                            >
                                                <AccordionSummary>{dayjs(date).format('DD.MM.YYYY')}</AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack
                                                    sx={{
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        flexDirection: { xs: 'column', sm: 'row' },
                                                    }}
                                                    >
                                                        <label>{modalStore?.workingHours?.specialHours[date].openUtc || '08:00:00'} to {modalStore?.workingHours?.specialHours[date].closeUtc || '17:00:00'}</label>
                                                       
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            variant='soft'                                                            
                                                            onClick={() => deleteSpecialDay(date)}
                                                        ><DeleteIcon /></Button>
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>))}
                                        </AccordionGroup>
                                        <Divider sx={{backgroundColor:'#1a66b2', marginBottom: '0.5rem'}} />
                                        <Box>
                                            <Stack flexDirection={'column'} gap={1}>
                                                <span>Add day:</span>
                                                <Input
                                                    placeholder="Date"
                                                    type="date"
                                                    value={dayjs(tmpSpecialDay?.date).format('YYYY-MM-DD')}
                                                    onChange={(e) => setTmpSpecialDay({
                                                        ...tmpSpecialDay,
                                                        date: ChangeDateTime('date', tmpSpecialDay?.date, e.target.value)
                                                    })}
                                                    endDecorator={
                                                    <React.Fragment>
                                                        <Divider orientation="vertical" />
                                                        <Select
                                                        variant="plain"
                                                        placeholder="Open/Closed"
                                                        value={tmpSpecialDay?.isClosed || false}
                                                        onChange={(e, newValue) => setTmpSpecialDay({...tmpSpecialDay, isClosed: newValue === "true"})}
                                                        slotProps={{
                                                            listbox: {
                                                            variant: 'outlined',
                                                            },
                                                        }}
                                                        sx={{ mr: -1.5, '&:hover': { bgcolor: 'transparent' } }}
                                                        >
                                                        <Option value="true">Closed</Option>
                                                        <Option value="false">Open</Option>
                                                        </Select>
                                                    </React.Fragment>
                                                    }
                                                />
                                                <Input 
                                                    startDecorator={'From:'}
                                                    variant="soft" 
                                                    size="sm" 
                                                    type="time"
                                                    value={dayjs(tmpSpecialDay.openUtc).format('HH:mm')}
                                                    onChange={(e) => setTmpSpecialDay({
                                                        ...tmpSpecialDay,
                                                        openUtc: dayjs(tmpSpecialDay.openUtc).hour(parseInt(e.target.value.split(":")[0])).minute(parseInt(e.target.value.split(":")[1])).format()
                                                    })}
                                                />
                                                <Input 
                                                    startDecorator={'To:'}
                                                    variant="soft" 
                                                    size="sm" 
                                                    type="time"
                                                    value={dayjs(tmpSpecialDay.closeUtc).format('HH:mm')}
                                                    onChange={(e) => setTmpSpecialDay({
                                                        ...tmpSpecialDay,
                                                        closeUtc: dayjs(tmpSpecialDay.closeUtc).hour(parseInt(e.target.value.split(":")[0])).minute(parseInt(e.target.value.split(":")[1])).format()
                                                    })}
                                                />
                                                <Button
                                                    color="primary"
                                                    disabled={tmpSpecialDay.isClosed === null || tmpSpecialDay.openUtc === null || tmpSpecialDay.closeUtc === null }
                                                    startDecorator={<AddIcon />}
                                                    size="sm"
                                                    variant='soft'
                                                    onClick={() => addSpecialDay()}
                                                >
                                                    Add 
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </TabPanel>
                                    <TabPanel value={2}>
                                        <AccordionGroup sx={{ maxWidth: 400 }}>
                                            {Object.keys(modalStore?.workingHours?.holidays).map((date) => (<Accordion
                                                expanded={holidayDay.date === date}
                                                onChange={(event, expanded) => {
                                                setHolidayDay({...holidayDay, date: expanded ? date : null});
                                                }}
                                            >
                                                <AccordionSummary>{dayjs(date).format('DD.MM.YYYY')}</AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack
                                                    sx={{
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                        flexDirection: { xs: 'column', sm: 'row' },
                                                    }}
                                                    >
                                                        <label>{modalStore?.workingHours?.holidays[date].name}</label>
                                                       
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            variant='soft'                                                            
                                                            onClick={() => deleteHolidayDay(date)}
                                                        ><DeleteIcon /></Button>
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>))}
                                        </AccordionGroup>
                                        <Divider sx={{backgroundColor:'#1a66b2', marginBottom: '0.5rem'}} />
                                        <Box>
                                            <Stack flexDirection={'column'} gap={1}>
                                                <span>Add day:</span>
                                                <Input
                                                    placeholder="Date"
                                                    type="date"
                                                    value={dayjs(tmpHolidayDay?.date).format('YYYY-MM-DD')}
                                                    onChange={(e) => setTmpHolidayDay({
                                                        ...tmpHolidayDay,
                                                        date: ChangeDateTime('date', tmpHolidayDay?.date, e.target.value)
                                                    })}
                                                    endDecorator={
                                                    <React.Fragment>
                                                        <Divider orientation="vertical" />
                                                        <Select
                                                        variant="plain"
                                                        placeholder="Open/Closed"
                                                        value={tmpHolidayDay?.holidayWorkStatus || 0}
                                                        onChange={(e, newValue) => setTmpHolidayDay({...tmpHolidayDay, holidayWorkStatus: newValue})}
                                                        slotProps={{
                                                            listbox: {
                                                            variant: 'outlined',
                                                            },
                                                        }}
                                                        sx={{ mr: -1.5, '&:hover': { bgcolor: 'transparent' } }}
                                                        >
                                                        <Option value="0">Closed</Option>
                                                        <Option value="1">Open</Option>
                                                        </Select>
                                                    </React.Fragment>
                                                    }
                                                />
                                                <Input 
                                                    startDecorator={'Name:'}
                                                    variant="soft" 
                                                    size="sm" 
                                                    value={tmpHolidayDay.name}
                                                    onChange={(e) => setTmpHolidayDay({
                                                        ...tmpHolidayDay,
                                                        name: e.target.value
                                                    })}
                                                />
                                                <Button
                                                    color="primary"
                                                    disabled={tmpHolidayDay.holidayWorkStatus === null || tmpHolidayDay.name === null }
                                                    startDecorator={<AddIcon />}
                                                    size="sm"
                                                    variant='soft'
                                                    onClick={() => addHolidayDay()}
                                                >
                                                    Add 
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </TabPanel>
                                </Tabs>
                            </TabPanel>
                        </Tabs>
                        <Button type="submit">Save</Button>
                    </Stack>
                    </form>
                    </ModalOverflow>}
                </ModalDialog>
            </Modal>

            <AppDeleteItemModal
                title={'Delete store'}
                description={'Are you sure you want to delete store?'}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                deleteFunction={deleteStore}
            />
        </Layout>
    );
};

export default Stores;