import React, {useState, useEffect, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import { Box,
    Button,
    Stack, 
    DialogTitle, 
    DialogContent, 
    LinearProgress, 
    Stepper, 
    Step, 
    StepButton, 
    StepIndicator, 
    FormControl, 
    FormLabel, 
    Input, 
    Modal, 
    ModalDialog, 
    ModalClose, 
    Select, 
    Option,
    Sheet,
    Typography,
    Divider,
    Tabs,
    TabList,
    Tab,
    TabPanel
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/joy/IconButton';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../store/loadingSlice'
import { toast } from 'react-toastify';
import Layout from '../../template/layout';
import {
    GetAllZones, 
    GetZone,
    CreateZone,
    UpdateZone,
    DeleteZone,
    Zone
} from '../../api/zones';
import { GetAllStores, Store } from '../../api/stores';
import AppDeleteItemModal from '../../components/AppDeleteItemModal';
import AceGrid, { HeadCell } from '../../components/AceGrid';
import AppHeader from '../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../components/AppBreadcrumb';
import uuid from '../../res/uuid';
import ZonesList from './ZonesList';

const Zones = () => {
    const defaultZone:Zone = {
        id: uuid(),
        organizationId: localStorage.getItem('organization_id') || "",
        storeId: '',
        store: '',
        name: '',
        code: null,
        location: null,
        zoneActivity: null,
        zoneCategory: null,
        zoneIlustrationUrl: null,
        zoneThreshold: {
          name: null,
          type: null,
        }
    };

    const [zones, setZones] = useState<Zone[]>([]);
    const [tmpZones, setTmpZones] = useState<Zone[]>(zones);
    const [openZoneModal, setOpenZoneModal] = useState(false);  
    const [modalZone, setModalZone] = useState<Zone>(defaultZone); 
    const [modalLoading, setModalLoading] = useState(false);
    const [stores, setStores] = useState<Store[] | null>(null);
    const [categories, setCategories] = useState<any>([]); 
    const [editId, setEditId] = useState<string | null>(null); 
    const [deleteId, setDeleteId] = useState<string | null>(null); 
    const organization_id = localStorage.getItem('organization_id') || "";
    const [searchByName, setSearchByName] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);  
    
    const [selectedCategory, setSelectedCategory] = useState<string>('');  
    const [selectedStore, setSelectedStore] = useState<string>(''); 
    
    const dispatch = useDispatch();
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };
    const getStoreName = (id:string) => {
        return stores ? (stores.find((item:Store) => item.id === id)?.name || '') : '';
    };
  
    const fetchZones = async () => {
        setLoading(true);
        setZones([]);
        GetAllZones(organization_id)
            .then(data => {    
                setZones(data?.map((itm:Zone) => ({
                    ...itm, store: getStoreName(itm.storeId || '')
                })));   
                setCategories(Array.from(new Set(data?.map(item => item.zoneCategory))));
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchZone = async (id: string) => {
        if(id){
            setOpenZoneModal(true);
            setModalLoading(true);
            GetZone(organization_id, id)
                .then(org => {
                    org && setModalZone(org);
                    setModalLoading(false);
                })
                .catch(error => console.error(error));
        }
    };
  
    const fetchStores = async () => {
        setLoading(true);
        organization_id && GetAllStores(organization_id)
            .then(data => {
                setStores(data);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const newZone = () => {  
        setEditId(null);
        setDeleteId(null);      
        setModalZone(defaultZone);
        setOpenZoneModal(true);
    };

    useEffect(() => {
        setLoading(true);
        setZones([]);
        fetchStores();
    },[]);
  
    useEffect(() => {        
        editId && fetchZone(editId);      
    },[editId]);
    
    useEffect(() => {
        stores && fetchZones();
    }, [stores]);
    
    useEffect(() => {
        setTmpZones(zones);
    }, [zones]);

    useEffect(() => {
        setTmpZones(zones.filter((item:Zone) => 
            ((item?.name.toLowerCase()+item.store.toLowerCase()).includes(searchByName.toLowerCase())
            && (selectedCategory.length ? (item?.zoneCategory?.toLowerCase() === selectedCategory.toLowerCase()) : true)
            && (selectedStore.length ? (item?.storeId?.toLowerCase() === selectedStore.toLowerCase()) : true)
        )));
    }, [searchByName, selectedCategory, selectedStore]);


    const capitalizeFirstLetter = (txt:string) => {
        return txt.charAt(0).toUpperCase() + txt.slice(1)
    }
  
    const updateZone = async ( zone: Zone) => {
      try {
        // Use the same form to Create or Update Organization
        setLoading(true);
        setZones([]);
        if(editId){
            UpdateZone(organization_id, zone)
                .then(dev => {
                    fetchZones();
                    setOpenZoneModal(false);
                    setEditId(null);
                })
                .catch(error => console.error(error));
        } else {
            CreateZone(organization_id, zone)
                .then(user => {
                    fetchZones();
                    setOpenZoneModal(false);     
                    setModalZone(defaultZone);
                })
                .catch(error => console.error(error));
        }
      } catch (error) {
        console.log(error);
      }
    };

    const DeleteDev= async (id: string) => {
        if(id){
            try {
            setLoading(true);
            setZones([]);
            DeleteZone(organization_id, id)
                .then(dev => {
                    fetchZones();
                    setDeleteId(null);
                })
                .catch(error => console.error(error));
            } catch (error) {
            console.log(error);
            }
        }
    };

    const deleteZone = async (id: string) => {
      id && await DeleteDev(id);
      setOpenZoneModal(false);
      setDeleteId(null);
    };
    
    const setDeleteList = (data:string[]) => {
        // console.log("Delete List: ", data);  
        toast.warning("We need to update API!");
    };

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Zones',
            url: '/zones',
            current: true
        },
    ];

    const headCells: readonly HeadCell[] = [
        {
            id: 'store',
            numeric: false,
            disablePadding: false,
            label: 'Store',
            type: 'string',
        },
        {
            id: 'zoneCategory',
            numeric: false,
            disablePadding: false,
            label: 'Category',
            type: 'chip',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: 'Name',
            type: 'string',
        }
    ];


    const renderFilters = () => (
      <Fragment>
        <FormControl size="sm">
            <FormLabel>Category</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                value={selectedCategory}
                disabled={!stores}
                onChange={(e, newValue) => setSelectedCategory(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {categories.map((x:string) => x && <Option key={x} value={x}>{capitalizeFirstLetter(x.replaceAll('-',' ').replaceAll('_',' '))}</Option>)}
            </Select>
        </FormControl>
        <FormControl size="sm">
            <FormLabel>Store</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                value={selectedStore}
                disabled={!stores}
                onChange={(e, newValue) => setSelectedStore(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {stores && stores.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
            </Select>
        </FormControl>
      </Fragment>
    );

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >                
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1552028650-e8207c3026f9?auto=format&w=1000&dpr=2'}
                    title={'Zones'}
                >
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => fetchZones()}
                    >
                        <ReplayOutlinedIcon />
                    </Button>
                    <Button
                        color="primary"
                        startDecorator={<AddIcon />}
                        size="sm"
                        onClick={() => newZone()}
                    >
                        Add Zone
                    </Button>
                </AppHeader>

                {/* Filters - Mobile    */}
                <Sheet
                    className="SearchAndFilters-mobile"
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        my: 1,
                        gap: 1,
                    }}
                >
                    <Input
                        size="sm"
                        variant='soft'
                        placeholder="Search"
                        value={searchByName} onChange={e => setSearchByName(e.target.value)}
                        startDecorator={<SearchIcon />}
                        sx={{ flexGrow: 1 }}
                    />
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => setFiltersOpen(true)}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
                        <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderFilters()}
                            <Button color="primary" onClick={() => setFiltersOpen(false)}>
                            Submit
                            </Button>
                        </Sheet>
                        </ModalDialog>
                    </Modal>
                </Sheet>

                {/* Filters- Web */}

                <Box
                    className="SearchAndFilters-tabletUp"
                    sx={{
                    buserRadius: 'sm',
                    py: 2,
                    display: { xs: 'none', sm: 'flex' },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: { xs: '120px', md: '160px' },
                    },
                    }}
                >
                    <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for zone</FormLabel>
                    <Input value={searchByName} onChange={e => setSearchByName(e.target.value)} size="sm" variant='soft' placeholder="Search" startDecorator={<SearchIcon />} />
                    </FormControl>
                    {renderFilters()}
                </Box>

                <AceGrid
                    headers={headCells}
                    data={tmpZones}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                    deleteList={setDeleteList}
                    defaultSortBy={'store'}
                    defaultSort={'desc'}
                    defaultId={'id'}
                />

                <ZonesList
                    zones={tmpZones}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                />

            </Box>
            
            <Modal open={openZoneModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setModalZone(defaultZone);
                    setOpenZoneModal(false);
                    setEditId(null);
                    setDeleteId(null);    
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                    <ModalClose />
                    <DialogTitle>Zone</DialogTitle>
                    <DialogContent>Insert zone information<br />&nbsp;</DialogContent>
                    
                {modalLoading ? <LinearProgress
                    color="primary"
                    determinate={false}
                    size="sm"
                    variant="soft"
                    /> :
                    <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                         event.preventDefault();
                        updateZone(modalZone);
                    }}
                    >
                    <Stack spacing={2}>
                        <Tabs aria-label="Basic tabs" defaultValue={0}>
                            <TabList>
                                <Tab>Details</Tab>
                                <Tab>Settings</Tab>
                            </TabList>
                            <TabPanel value={0}>
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormLabel>Name</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalZone.name || ''}
                                        onChange={(e) => setModalZone({
                                            ...modalZone,
                                            name: e.target.value
                                            }
                                        )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Category</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalZone.zoneCategory || ''}
                                        onChange={(e) => setModalZone({
                                            ...modalZone,
                                            zoneCategory: e.target.value
                                            }
                                        )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Store</FormLabel>
                                        <Select placeholder="Select store"
                                            size="sm"
                                            variant="soft"
                                        value={modalZone?.storeId}
                                            disabled={!stores}
                                            onChange={(e, newValue) => setModalZone({
                                                ...modalZone,
                                                storeId: newValue !== null ? newValue : ''
                                            })}
                                        >
                                            {stores && stores.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Code</FormLabel>
                                        <Input 
                                        variant="soft" 
                                        size="sm" 
                                        disabled
                                        value={modalZone.code || ''}
                                        />
                                    </FormControl>
                                </Stack>
                            </TabPanel>
                            <TabPanel value={1}>
                                <Stack spacing={2}>
                                    <FormControl>
                                        <FormLabel>Zone activity</FormLabel>
                                        <Select placeholder="Zone activity"
                                            size="sm"
                                            variant="soft"
                                        value={modalZone?.zoneActivity}
                                            disabled={!stores}
                                            onChange={(e, newValue) => setModalZone({
                                                ...modalZone,
                                                zoneActivity: newValue
                                            })}
                                        >
                                            <Option value={1}>Moderate</Option>
                                            <Option value={2}>High</Option>
                                            <Option value={0}>Low</Option>
                                            <Option value={null}>Inactive</Option>
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Illustration url</FormLabel>
                                        <Input 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalZone.zoneIlustrationUrl || ''}
                                        onChange={(e) => setModalZone({
                                            ...modalZone,
                                            zoneIlustrationUrl: e.target.value
                                            }
                                        )}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Threshold name</FormLabel>
                                        <Input 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalZone?.zoneThreshold?.name || ''}
                                        onChange={(e) => setModalZone({
                                            ...modalZone,
                                            zoneThreshold: {
                                                ...modalZone.zoneThreshold,
                                                name: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Threshold type</FormLabel>
                                        <Input 
                                        variant="soft" 
                                        size="sm" 
                                        value={modalZone?.zoneThreshold?.type || ''}
                                        onChange={(e) => setModalZone({
                                            ...modalZone,
                                            zoneThreshold: {
                                                ...modalZone.zoneThreshold,
                                                type: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                </Stack>
                            </TabPanel>
                        </Tabs>
                        <Button type="submit">Save</Button>
                    </Stack>
                    </form>}
                </ModalDialog>
            </Modal>

            <AppDeleteItemModal
                title={'Delete zone'}
                description={'Are you sure you want to delete zone?'}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                deleteFunction={deleteZone}
            />
        </Layout>
    );
};

export default Zones;