import axios from "axios";
import headers from "./headers";
import redirect from "./redirect";

// https://organization.api.acesolutions.no/api/Swagger/ui#/

export interface Store {
    id: string,
    organizationId: string,
    name: string,
    address: string,
    isAutomaticStore: boolean,
    isOpen: boolean,
    workingHours: any | null,
}

export const GetAllStores = async (org_id: string): Promise<Store[]> => {
    try {
        const { data } = await axios.get<Store[]>(`${process.env.REACT_APP_STORE_API}/api/organizations/${org_id}/stores`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch stores:', error);
        throw error;
    }
}

export const GetStore = async (id: string, org_id: string): Promise<Store> => {
    try {
        const { data } = await axios.get<Store>(`${process.env.REACT_APP_STORE_API}/api/organizations/${org_id}/stores/${id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch store:', error);
        throw error;
    }
}

export const CreateStore = async (store: Store): Promise<Store> => {
    try {
        const { data } = await axios.post<Store>(`${process.env.REACT_APP_STORE_API}/api/organizations/${store.organizationId}/stores`, store, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to create store:', error);
        throw error;
    }
}

export const UpdateStore = async (id: string, store: Store): Promise<Store> => {
    try {
        const { data } = await axios.put<Store>(`${process.env.REACT_APP_STORE_API}/api/organizations/${store.organizationId}/stores/${id}`, store, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update store:', error);
        throw error;
    }
}

export const DeleteStore = async (id: string, organization_id: string) => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_STORE_API}/api/organizations/${organization_id}/stores/${id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to delete store:', error);
        throw error;
    }
}