import React from 'react';
import {QRCodeSVG} from 'qrcode.react';

const QRCodeComponent = ({ url = '', size = 256 }) => {
  return (
    <div>
      <QRCodeSVG value={url} size={size} style={{border: '10px solid #fff', borderRadius: '5px'}} />
    </div>
  );
};

export default QRCodeComponent;
