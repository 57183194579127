import React, { FC, useState, useEffect } from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog } from "@mui/joy";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';


export interface props {
    title: string,
    description: string,
    deleteId: string | null,
    setDeleteId: Function,
    deleteFunction: Function
}

const AppDeleteItemModal = ({ title, description, deleteId, setDeleteId, deleteFunction }: props) => {
    return (
        <Modal open={deleteId !== null} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
            if(reason !== 'backdropClick'){
                setDeleteId(null);
            }
        }} >
            <ModalDialog layout='center' variant='plain'>
            <DialogTitle>
                <WarningRoundedIcon />
                {title}
            </DialogTitle>
            <Divider />
            <DialogContent>
                {description}
            </DialogContent>
            <DialogActions>
                <Button variant="solid" color="danger" onClick={() => deleteId && deleteFunction(deleteId)}>
                    Delete
                </Button>
                <Button variant="plain" color="neutral" onClick={() => setDeleteId(null)}>
                    Cancel
                </Button>
            </DialogActions>
            </ModalDialog>
        </Modal>);
};
export default AppDeleteItemModal;


// // Send from parent component

// <AppDeleteItemModal
//  title={'Delete visitor'}
//  description={'Are you sure you want to delete visitor?'}
//  deleteId={deleteId}
//  setDeleteId={setDeleteId}
//  deleteFunction={deleteVisitor}
// />