import axios from "axios";
import headers from "./headers";
import redirect from "./redirect";

// https://organization.api.acesolutions.no/api/Swagger/ui#/

export interface Org {
    id: string,
    name: string,
}

export interface DashboardStatistics {
    organizationId: string,
    storeStatistics: {
        openStores: number,
        closedStores: number,
        automaticStores: number,
        totalStores: number
    },
    zoneStatistics: {
        lowActivityZones: number,
        moderateActivityZones: number,
        highActivityZones: number,
        inactiveZones: number,
        totalZones: number
    },
    userStatistics: {
        activeUsers: number,
        vacationingUsers: number,
        backWatchUsers: number,
        offWorkUsers: number,
        totalUsers: number
    },
    deviceStatistics: {
        activeDevices: number,
        offlineDevices: number,
        failureDevices: number,
        totalDevices: number
    }
}

export const GetAllOrganizations = async (): Promise<Org[]> => {
    try {
        const { data } = await axios.get<Org[]>(`${process.env.REACT_APP_ORGANIZATION_API}/api/organizations`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch organizations:', error);
        return [];
    }
}

export const GetOrganization = async (id: string): Promise<Org | null> => {
    try {
        const { data } = await axios.get<Org>(`${process.env.REACT_APP_ORGANIZATION_API}/api/organizations/${id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch organization:', error);
        return null;
    }
}

export const CreateOrganization = async (organization: Org): Promise<Org | null> => {
    try {
        const { data } = await axios.post<Org>(`${process.env.REACT_APP_ORGANIZATION_API}/api/organizations`, organization, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to create organization:', error);
        return null;
    }
}

export const UpdateOrganization = async (organization: Org): Promise<Org | null> => {
    try {
        const { data } = await axios.put<Org>(`${process.env.REACT_APP_ORGANIZATION_API}/api/organizations/${organization.id}`, organization, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to update organization:', error);
        return null;
    }
}

export const DeleteOrganization = async (id: string) => {
    try {
        const { data } = await axios.delete(`${process.env.REACT_APP_ORGANIZATION_API}/api/organizations/${id}`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to delete organization:', error);
        return null;
    }
}

export const GetOrganizationStatistics = async (id: string): Promise<DashboardStatistics | null> => {
    try {
        const { data } = await axios.get<DashboardStatistics>(`${process.env.REACT_APP_ORGANIZATION_API}/api/organizations/${id}/statistics`, {
            headers: headers(localStorage.getItem('id_token'))
        });   
        return data;   
    } catch (error:any) {
        error.response.status === 401 && redirect();
        console.error('Failed to fetch organizations:', error);
        return null;
    }
}