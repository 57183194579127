import React, {useState, useEffect, Fragment} from 'react';
import { Box, Button,
    DialogContent,
    DialogTitle,
    Modal,
    ModalClose,
    ModalDialog,
    ModalOverflow,
    Textarea
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import Layout from '../../template/layout';
import AppHeader from '../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../components/AppBreadcrumb';

import Form from '@rjsf/core';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';

const DeviceTypes = () => {
    const tmpschema: RJSFSchema = {
      "type": "object",
      "properties": {
        "deviceStatus": {
          "type": "object",
          "title": "DEVICE STATUS",
          "properties": {
            "isOnline": {
              "type": "boolean",
              "title": "Device Online"
            },
            "lastService": {
              "type": "string",
              "format": "date",
              "title": "Last Service Date"
            },
            "temperature": {
              "type": "number",
              "title": "Temperature (°C)"
            },
            "gateState": {
              "type": "string",
              "enum": [
                "Open",
                "Closed",
                "Fault",
                "Maintenance"
              ],
              "title": "Gate State"
            },
            "errorCode": {
              "type": "string",
              "title": "Error Code"
            },
            "entryMode": {
              "type": "string",
              "enum": [
                "Blocked",
                "Free",
                "Token",
                "Emergency"
              ],
              "title": "Entry Side Mode"
            },
            "exitMode": {
              "type": "string",
              "enum": [
                "Blocked",
                "Free",
                "Token",
                "Emergency"
              ],
              "title": "Exit Side Mode"
            }
          }
        },
        "statistics": {
          "type": "object",
          "title": "STATISTICS",
          "properties": {
            "entrances": {
              "type": "object",
              "title": "Entrances",
              "properties": {
                "blocked": {
                  "type": "integer",
                  "title": "Blocked Entrances"
                },
                "free": {
                  "type": "integer",
                  "title": "Free Entrances"
                },
                "token": {
                  "type": "integer",
                  "title": "Token Entrances"
                },
                "emergency": {
                  "type": "integer",
                  "title": "Emergency Entrances"
                }
              }
            },
            "exits": {
              "type": "object",
              "title": "Exits",
              "properties": {
                "blocked": {
                  "type": "integer",
                  "title": "Blocked Exits"
                },
                "free": {
                  "type": "integer",
                  "title": "Free Exits"
                },
                "token": {
                  "type": "integer",
                  "title": "Token Exits"
                },
                "emergency": {
                  "type": "integer",
                  "title": "Emergency Exits"
                }
              }
            }
          }
        },
        "actions": {
          "type": "object",
          "title": "ACTIONS",
          "properties": {
            "lockGate": {
              "type": "boolean",
              "title": "Lock Gate"
            },
            "openGate": {
              "type": "boolean",
              "title": "Open Gate"
            },
            "resetGate": {
              "type": "boolean",
              "title": "Reset Gate"
            },
            "emergency": {
              "type": "boolean",
              "title": "Emergency Mode"
            }
          }
        }
      }
    };

    const tmpuischema: UiSchema  = {
      "type": "Categorization",
      "elements": [
        {
          "type": "Category",
          "label": "Device Status",
          "elements": [
            {
              "type": "Control",
              "scope": "#/properties/deviceStatus/properties/isOnline"
            },
            {
              "type": "Control",
              "scope": "#/properties/deviceStatus/properties/lastService"
            },
            {
              "type": "Control",
              "scope": "#/properties/deviceStatus/properties/temperature"
            },
            {
              "type": "Control",
              "scope": "#/properties/deviceStatus/properties/gateState"
            },
            {
              "type": "Control",
              "scope": "#/properties/deviceStatus/properties/errorCode"
            },
            {
              "type": "Control",
              "scope": "#/properties/deviceStatus/properties/entryMode"
            },
            {
              "type": "Control",
              "scope": "#/properties/deviceStatus/properties/exitMode"
            }
          ]
        },
        {
          "type": "Category",
          "label": "Statistics",
          "elements": [
            {
              "type": "Group",
              "label": "Entrances",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/statistics/properties/entrances/properties/blocked"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/statistics/properties/entrances/properties/free"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/statistics/properties/entrances/properties/token"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/statistics/properties/entrances/properties/emergency"
                }
              ]
            },
            {
              "type": "Group",
              "label": "Exits",
              "elements": [
                {
                  "type": "Control",
                  "scope": "#/properties/statistics/properties/exits/properties/blocked"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/statistics/properties/exits/properties/free"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/statistics/properties/exits/properties/token"
                },
                {
                  "type": "Control",
                  "scope": "#/properties/statistics/properties/exits/properties/emergency"
                }
              ]
            }
          ]
        },
        {
          "type": "Category",
          "label": "Actions",
          "elements": [
            {
              "type": "Control",
              "scope": "#/properties/actions/properties/lockGate"
            },
            {
              "type": "Control",
              "scope": "#/properties/actions/properties/openGate"
            },
            {
              "type": "Control",
              "scope": "#/properties/actions/properties/resetGate"
            },
            {
              "type": "Control",
              "scope": "#/properties/actions/properties/emergency"
            }
          ]
        }
      ]
    };

    const tmpdata = {
      "deviceStatus": {
        "isOnline": true,
        "lastService": "2024-09-01",
        "temperature": 22.5,
        "gateState": "Open",
        "errorCode": "None",
        "entryMode": "Token",
        "exitMode": "Blocked"
      },
      "statistics": {
        "entrances": {
          "blocked": 0,
          "free": 150,
          "token": 75,
          "emergency": 3
        },
        "exits": {
          "blocked": 1,
          "free": 160,
          "token": 70,
          "emergency": 2
        }
      },
      "actions": {
        "lockGate": false,
        "openGate": true,
        "resetGate": false,
        "emergency": false
      }
    };

    const [data, setData] = useState(tmpdata);
    const [schema, setSchema] = useState<RJSFSchema>(tmpschema);
    const [uischema, setUISchema] = useState<UiSchema>(tmpuischema);
    const [openModal, setOpenModal] = useState(false);  



    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'JSON Forms',
            url: '/device-types',
            current: true
        },
    ];

  
    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >                
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1634224147987-95d2b7679fb0?auto=format&w=1000&dpr=2'}
                    title={'JSON Forms'}
                >
                </AppHeader>
                <label>Schema</label>
                <Textarea minRows={5} maxRows={5} value={JSON.stringify(schema)} onChange={(e) => setSchema(JSON.parse(e.target.value))} sx={{ mb: 1 }} />
                <label>UI Schema</label>
                <Textarea minRows={5} maxRows={5} value={JSON.stringify(uischema)} onChange={(e) => setUISchema(JSON.parse(e.target.value))} sx={{ mb: 1 }} />
                <label>Data</label>
                <Textarea minRows={5} maxRows={5} value={JSON.stringify(data)} onChange={(e) => setData(JSON.parse(e.target.value))} sx={{ mb: 3 }} />
                <Button onClick={() => setOpenModal(true)}>Test Modal</Button>
            </Box>
            
            <Modal open={openModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                setOpenModal(false);
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                    <ModalClose />
                    <DialogTitle>Device type</DialogTitle>
                    <ModalOverflow sx={{ position: 'relative', padding: '0px'}}>
                      <Form onChange={(data) => setData(data.formData)} schema={schema} uiSchema={uischema} validator={validator} formData={data} >
                        <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          justifyContent: 'space-between',
                          flexDirection: { xs: 'column', sm: 'row' },
                        }}>
                          <Button onClick={() =>{console.log(data); setOpenModal(false);}} sx={{ flex: 1 }}>Save</Button>
                        </Box>
                      </Form>
                    </ModalOverflow>
                </ModalDialog>
            </Modal>
        </Layout>
    );
};

export default DeviceTypes;