import React, { useEffect, useState  } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../template/layout";
import { Box, Breadcrumbs, Button, Card, Stack, CardContent, CardCover, FormControl, FormLabel, Input, Link, Select, Option, Typography, Grid, Avatar } from "@mui/joy";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../store/loadingSlice'
import { GetUserProfile, DeleteUserProfile, UpdateUserProfile, UserProfile } from '../../api/users';
import countries from '../../res/countries';

const User = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [editUser, setEditUser] = useState<UserProfile | null>(); 
    const loggedUser = useSelector((state:any) => state.auth.logged);
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };

    const fetchUserProfile = async (id: string) => {
        if(id){
            setLoading(true);
            GetUserProfile(id)
                .then(user => {
                    setEditUser(user);
                    setLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };
  
    const updateUserProfile = async (id: string, user: UserProfile) => {
        setLoading(true);
        UpdateUserProfile(id, user)
            .then(user => {
                setEditUser(user);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };
  
    useEffect(() => {
        fetchUserProfile(loggedUser.sub);
    },[]);

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Breadcrumbs    
                        size="sm"
                        aria-label="breadcrumbs"
                        separator={<ChevronRightRoundedIcon />}
                        sx={{ pl: 0 }}
                    >
                        <Link
                        underline="none"
                        color="neutral"
                        aria-label="Home"
                        onClick={() => navigate('/')}
                        >
                        <HomeRoundedIcon />
                        </Link>
                        <Link
                        underline="hover"
                        color="neutral"
                        fontSize={12}
                        fontWeight={500}
                        onClick={() => navigate('/')}
                        >
                            Dashboard
                        </Link>
                        <Typography color="primary" fontWeight={500} fontSize={12}>
                            User
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Card component="div" variant='plain' sx={{  flexGrow: 1 }} >
                        <CardCover>
                        <img
                            className='banner'
                            src={'https://images.unsplash.com/photo-1517940001917-1c03b8b1b85b?auto=format&w=1000&dpr=2'}
                            srcSet={'https://images.unsplash.com/photo-1517940001917-1c03b8b1b85b?auto=format&w=1000&dpr=2'}
                            loading="lazy"
                            alt=""
                        />
                        </CardCover>
                        <CardCover
                            sx={(theme) => ({
                            background: 'linear-gradient(to top, rgba(255,255,255,0.2), rgba(255,255,255,0.8)), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                            [theme.getColorSchemeSelector('dark')]: {
                                background: 'linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0.5)), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 300px)',
                            },
                            })}
                        />
                        <CardContent>
                            <Box
                            sx={{
                                display: 'flex',
                                mb: 1,
                                gap: 1,
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'start', sm: 'end' },
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                            }}
                            >
                                <Typography
                                    level="h2"
                                    component="h1"
                                    fontWeight="lg"
                                    textColor="#fff"
                                    mt={{ xs: 12, sm: 18 }}
                                >
                                    <Avatar variant="solid" size="lg" /> My profile
                                </Typography>
                            <Button
                                color="primary"
                                startDecorator={<CheckOutlinedIcon />}
                                size="sm"
                                onClick={() => editUser && updateUserProfile(editUser.userId, editUser)}
                            >
                                Save
                            </Button>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
                <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        editUser && updateUserProfile(editUser.userId, editUser);
                    }}
                    >
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                            sx={{ flexGrow: 1 }}
                        >
                            <Grid xs={4} sm={4} md={4}>
                                <Stack spacing={2}>
                                    <Typography level="h2" fontSize="xl" sx={{ mb: 0.5 }}>
                                        Personal
                                    </Typography>
                                    <FormControl>
                                        <FormLabel>First name</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.personalInformation?.firstName || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            personalInformation: {
                                            ...editUser.personalInformation,
                                            firstName: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Last name</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.personalInformation?.lastName || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            personalInformation: {
                                            ...editUser.personalInformation,
                                            lastName: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Email</FormLabel>
                                        <Input 
                                        required 
                                        type='email'
                                        variant="soft" 
                                        size="sm" 
                                        disabled
                                        value={editUser?.personalInformation?.email || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            personalInformation: {
                                            ...editUser.personalInformation,
                                            email: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Country</FormLabel>
                                        <Select
                                        placeholder="Select country"
                                        size="sm"
                                        variant="soft"
                                        value={editUser?.personalInformation?.phonePrefix || ''}
                                        onChange={(e, newValue) => editUser && setEditUser({
                                            ...editUser,
                                            personalInformation: {
                                            ...editUser.personalInformation,
                                            phonePrefix: newValue || ''
                                            }
                                        })}
                                        >
                                        {countries.map((item, index) => <Option key={index} value={item.phone}>
                                            <img
                                            loading="lazy"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                                            alt=""
                                            />
                                            {item.label}
                                        </Option>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Phone</FormLabel>
                                        <Input 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.personalInformation?.phoneNumber}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            personalInformation: {
                                            ...editUser.personalInformation,
                                            phoneNumber: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                </Stack>
                            </Grid>
                            <Grid xs={4} sm={4} md={4}>
                                <Stack spacing={2}>
                                    <Typography level="h2" fontSize="xl" sx={{ mb: 0.5 }}>
                                        Address
                                    </Typography>
                                    <FormControl>
                                        <FormLabel>City</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.userMainAddress?.city || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            userMainAddress: {
                                            ...editUser.userMainAddress,
                                            city: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Province</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.userMainAddress?.province || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            userMainAddress: {
                                            ...editUser.userMainAddress,
                                            province: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Post code</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.userMainAddress?.postCode || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            userMainAddress: {
                                            ...editUser.userMainAddress,
                                            postCode: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Address 1</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.userMainAddress?.addressLine1 || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            userMainAddress: {
                                            ...editUser.userMainAddress,
                                            addressLine1: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Address 2</FormLabel>
                                        <Input 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.userMainAddress?.addressLine2 || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            userMainAddress: {
                                            ...editUser.userMainAddress,
                                            addressLine2: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Address 3</FormLabel>
                                        <Input 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.userMainAddress?.addressLine3 || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            userMainAddress: {
                                            ...editUser.userMainAddress,
                                            addressLine3: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                </Stack> 
                            </Grid>
                            <Grid xs={4} sm={8} md={4}>
                                <Stack spacing={2}>
                                    <Typography level="h2" fontSize="xl" sx={{ mb: 0.5 }}>
                                        Company
                                    </Typography>
                                    <FormControl>
                                        <FormLabel>Company</FormLabel>
                                        <Input 
                                        variant="soft" 
                                        size="sm" 
                                        disabled
                                        value={editUser?.jobInformation?.companyName || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            jobInformation: {
                                            ...editUser.jobInformation,
                                            companyName: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Department</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.jobInformation?.companyDepartment || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            jobInformation: {
                                            ...editUser.jobInformation,
                                            companyDepartment: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Company phone</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.jobInformation?.companyPhone || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            jobInformation: {
                                            ...editUser.jobInformation,
                                            companyPhone: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>Office location</FormLabel>
                                        <Input 
                                        required 
                                        variant="soft" 
                                        size="sm" 
                                        value={editUser?.jobInformation?.officeLocation || ''}
                                        onChange={(e) => editUser && setEditUser({
                                            ...editUser,
                                            jobInformation: {
                                            ...editUser.jobInformation,
                                            officeLocation: e.target.value
                                            }
                                        })}
                                        />
                                    </FormControl>






                                </Stack> 
                            </Grid>
                        </Grid>
                </form>
            </Box>
        </Layout>
    );
};

export default User;