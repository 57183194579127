import React, { FC, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import GlobalStyles from '@mui/joy/GlobalStyles';
import {Box, Button, Link, CircularProgress, Typography, Stack, Modal } from '@mui/joy';
import IconButton, { IconButtonProps } from '@mui/joy/IconButton';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import { useNavigate } from "react-router-dom";
import type { AceState } from '../store'
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../store/loadingSlice'
import { login, logout } from '../store/aceSlice'
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

import logoGold from "../assets/images/logo-gold.svg";
import { ModalDialog } from "@mui/joy";
import FakeCountProgress from "../components/FakeCountProgress";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LinearProgressThickness from "../components/LinearProgressThickness";

export interface props {
    children?: React.ReactNode; }

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

function ColorSchemeToggle(props: IconButtonProps) {
  const { onClick, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  return (
    <IconButton
      aria-label="toggle light/dark mode"
      size="sm"
      variant="outlined"
      disabled={!mounted}
      onClick={(event) => {
        setMode(mode === 'light' ? 'dark' : 'light');
        onClick?.(event);
      }}
      {...other}
    >
      {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  );
}

const Layout = ({ children }: props) => {
  const [idToken, setIdToken] = useState(localStorage.getItem('id_token'));
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const jwt_token = useSelector((state: AceState) => state.auth.jwt_token);
  const dispatch = useDispatch();
  const is_loading = useSelector((state:any) => state.loading.is_loading);

  const setLoading = (load: boolean) => {
    dispatch(loading(load));
  };

  const consoleLog = (load: boolean) => {
    console.log("Loading: ", load);
  };

  const client_id = '1c858639-9467-421c-a1f8-0d1e6d62562c';
  const navigate = useNavigate();

  useEffect(() =>{
      let hash = window.location.hash;
      hash = hash.substring(1);
      let params = new URLSearchParams(hash);
      let id_token = params.get('id_token') || localStorage.getItem('id_token');
      const user:any = id_token ? jwtDecode(id_token) : {};      
      if(id_token && user?.extension_customClaims !== ''){
        localStorage.setItem('id_token', id_token);
        !localStorage.getItem('organization_id') && localStorage.setItem('organization_id', user.extension_organizationId);
        localStorage.setItem('time', new Date().getTime().toString());
        setIdToken(id_token);
        dispatch(login(id_token)); 
        hash && navigate("/");   
      }
      else if(!jwt_token){
        localStorage.removeItem('id_token');
        localStorage.removeItem('organization_id');
        localStorage.removeItem('time');
        localStorage.removeItem("store_group_id");
        localStorage.removeItem("store_group_name");
        user?.extension_customClaims === '' && toast.error("Your profile is not active!");
        setIdToken(null);  
        dispatch(logout());   
        setLoading(false);
        navigate("/");
      } else {
        
      }
  },[]);

  useEffect(() =>{

  },[jwt_token]);

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      { idToken ?
      <Box sx={{ display: 'flex', minHeight: '100dvh', }}>
        <Header />
        <Sidebar />
        {children}
      </Box> : <>
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width: { xs: '100%', md: '50vw' },
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundColor: 'rgba(19 19 24 / 0.4)',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width: '100%',
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
          <Box sx={{ display: 'flex', gap: 1, flex: 1, justifyContent: 'start', alignItems: 'center' }}>
            <img src={logoGold} height='18px'/>
          </Box>
            <ColorSchemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .MuiFormLabel-asterisk`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
                <Typography component="h1" level="h3">
                  Sign in
                </Typography>
                <Typography level="body-sm">
                  New to company?{' '}
                  <Link href={`https://acesolutionplatform.b2clogin.com/AceSolutionPlatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUp&client_id=${client_id}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_ACESOLUTIONS_URL}&scope=openid&response_type=id_token&prompt=login`} level="title-sm">
                    Sign up!
                  </Link>
                </Typography>
                  <Button type="submit" fullWidth onClick={() => window.location.replace(`https://acesolutionplatform.b2clogin.com/AceSolutionPlatform.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignIn&client_id=${client_id}&nonce=defaultNonce&redirect_uri=${process.env.REACT_APP_ACESOLUTIONS_URL}&scope=openid&response_type=id_token&prompt=login`)}>
                    Sign in
                  </Button>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Ace Solutions AS {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: { xs: 0, md: '50vw' },
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1562778357-58e2feebe9aa?auto=format&w=1000&dpr=2)',
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              'url(https://images.unsplash.com/photo-1692392275019-4a709977332f?auto=format&w=1000&dpr=2)',
          },
        })}
      />
      </>}

      { is_loading && <LinearProgressThickness /> }
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={localStorage.getItem('joy-mode') === "dark"? "dark":"light"}
      />
    </CssVarsProvider>
  );
}

export default Layout;