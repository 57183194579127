import React, {useState, useEffect, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import { Box,
    Button,
    Stack, 
    DialogTitle, 
    DialogContent, 
    LinearProgress, 
    Stepper, 
    Step, 
    StepButton, 
    StepIndicator, 
    FormControl, 
    FormLabel, 
    Input, 
    Modal, 
    ModalDialog, 
    ModalClose, 
    Select, 
    Option,
    Sheet,
    Typography,
    Divider,
    Tabs,
    TabList,
    Tab,
    TabPanel
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/joy/IconButton';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../store/loadingSlice'
import { toast } from 'react-toastify';
import Layout from '../../template/layout';
import {
    GetAllSatisfactions,
    Satisfaction,
    SatisfactionDevice,
    Graph
} from '../../api/satisfaction';
import {
    GetAllOrganizationDevices, 
    Device
} from '../../api/devices';
import AppHeader from '../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../components/AppBreadcrumb';
import SatisfactionChart from '../../components/SatisfactionChart';

const Satisfactions = () => {
    const [satisfactions, setSatisfactions] = useState<Satisfaction | null>(null);
    const [satisfactionDevices, setSatisfactionDevices] = useState<SatisfactionDevice[]>([]);
    const [devices, setDevices] = useState<Device[]>([]);
    const organization_id = localStorage.getItem('organization_id') || "";
    
    const dispatch = useDispatch();
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };

    const getDeviceName = (id:string) => {
        return (devices && devices.find((item:Device) => item.id === id)?.deviceName || '');
    };
  
    const fetchDevices = async () => {
        setLoading(true);
        setDevices([]);
        organization_id && GetAllOrganizationDevices(organization_id)
            .then(data => {                             
                setDevices(data);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };
  
    const fetchSatisfactions = async () => {
        setLoading(true);
        setSatisfactions(null);
        GetAllSatisfactions(organization_id)
            .then(data => {    
                setSatisfactions(data);
                data?.devices && setSatisfactionDevices(data.devices.map((itm:SatisfactionDevice) => ({
                    ...itm, device: getDeviceName(itm.device_id || '')
                })));   
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchDevices();
    },[]);

    useEffect(() => {
        devices && fetchSatisfactions();
    },[devices]);

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Satisfactions',
            url: '/satisfactions',
            current: true
        },
    ];

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >                
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1554774853-b415df9eeb92?auto=format&w=1000&dpr=2'}
                    title={'Satisfactions'}
                >
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => fetchSatisfactions()}
                    >
                        <ReplayOutlinedIcon />
                    </Button>
                </AppHeader>
                {satisfactionDevices && satisfactionDevices.map((data, index) => <SatisfactionChart key={index} data={data} />)}
            </Box>
        </Layout>
    );
};

export default Satisfactions;