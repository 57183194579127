import React, {useState, useEffect} from 'react';
import { SatisfactionDevice } from '../api/satisfaction';
import Chart, { CategoryScale } from 'chart.js/auto';
import { Bar } from "react-chartjs-2";

Chart.register(CategoryScale);

const SatisfactionChart = (props:any) => {

    const { data } = props;
    return (
        <Bar
            data={{
                labels: [""],
                datasets: [
                    {
                        label: "Bad",
                        data: [data.distribution['1']],
                        backgroundColor: "#a30000"
                    },
                    {
                        label: "Solid",
                        data: [data.distribution['2']],
                        backgroundColor: "#d09200"
                    },
                    {
                        label: "Good",
                        data: [data.distribution['3']],
                        backgroundColor: "#00a0a0"
                    },
                    {
                        label: "Excellent",
                        data: [data.distribution['4']],
                        backgroundColor: "#189100"
                    },
                ]
            }}
            options={{
                plugins: {
                    title: {
                        display: true,
                        text: data.device,
                        position: 'top'
                    },
                    legend: {
                        display: true,
                        position: 'top'
                    }
                },
                scales: {
                    x: {
                        display: true,
                        border: {
                            color: '#4393e4'
                        }
                    },
                    y: {
                        display: true,
                        border: {
                            color: '#4393e4'
                        }
                    }
                }
                
            }}
        />
    );
};

export default SatisfactionChart;