import React, {useState, useEffect, Fragment} from 'react';
import ReactDOM from 'react-dom';
import Editor from '@monaco-editor/react';
import { useNavigate } from "react-router-dom";
import { Box,
    Button,
    Stack, 
    DialogTitle, 
    DialogContent, 
    LinearProgress, 
    Stepper, 
    Tabs,
    Step, 
    StepButton, 
    StepIndicator, 
    FormControl, 
    FormLabel, 
    Input, 
    Modal, 
    ModalDialog, 
    ModalClose, 
    Select, 
    Option,
    Sheet,
    Typography,
    Divider,
    TabPanel,
    TabList,
    Tab,
    ModalOverflow,
    ButtonGroup
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import BugReportIcon from '@mui/icons-material/BugReport';
import IconButton from '@mui/joy/IconButton';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { loading } from '../../store/loadingSlice';
import { toast } from 'react-toastify';
import Layout from '../../template/layout';
import {
    GetAllOrganizationDevices, 
    GetDevice,
    CreateDevice,
    UpdateDevice,
    DeleteDevice,
    GetAllDeviceTypes,
    GetDeviceScript,
    GetAllDeviceScriptTemplates,
    GetDeviceTypeScriptTemplate,
    EvaluateDeviceScript,
    UpdateDeviceScript,
    Device,
    defaultDevice,
    GetDeviceTypeData
} from '../../api/devices';
import { GetAllStores, Store } from '../../api/stores';
import AppDeleteItemModal from '../../components/AppDeleteItemModal';
import AceGrid, { HeadCell } from '../../components/AceGrid';
import AppHeader from '../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../components/AppBreadcrumb';
import DevicesList from './DevicesList';
import { GetAllZones, Zone } from '../../api/zones';

import { JsonForms } from '@jsonforms/react';
import {
    materialRenderers,
    materialCells,
  } from '@jsonforms/material-renderers';
import { Experimental_CssVarsProvider as CssVarsProvider, useColorScheme } from '@mui/material/styles';
import Add from '@mui/icons-material/Add';
import { log } from 'node:console';
import uuid from '../../res/uuid';

const Devices = () => {
    const loggedUser = useSelector((state:any) => state.auth.logged);

    const [devices, setDevices] = useState<Device[]>([]);
    const [tmpDevices, setTmpDevices] = useState<Device[]>(devices);
    const [openAddModal, setOpenAddModal] = useState(false); 
    const [openDevModal, setOpenDevModal] = useState(false);  
    const [modalDev, setModalDev] = useState<Device>(defaultDevice); 
    const [modalLoading, setModalLoading] = useState(false);
    const [zones, setZones] = useState<Zone[] | null>(null);
    const [stores, setStores] = useState<Store[] | null>(null);
    const [statuses, setStatuses] = useState<any>([]); 
    const [editId, setEditId] = useState<string | null>(null); 
    const [deleteId, setDeleteId] = useState<string | null>(null); 
    const organization_id = localStorage.getItem('organization_id') || "";
    const [searchByName, setSearchByName] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false); 
    
    const [deviceTypes, setDeviceTypes] = useState<string[] | null>(null); 
    const [deviceScriptTemplates, setDeviceScriptTemplates] = useState<string[] | null>(null); 
    
    const [selectedStatus, setSelectedStatus] = useState<string>('');  
    const [selectedZone, setSelectedZone] = useState<string>(''); 
    const [selectedStore, setSelectedStore] = useState<string>(''); 
    const [selectedDeviceType, setSelectedDeviceType] = useState<string>(''); 

    const [validScript, setValidScript] = useState<boolean>(false); 

    const [selectedModDeviceType, setSelectedModDeviceType] = useState<string>(''); 
    
    const [loadingScript, setLoadingScript] = useState<boolean>(false); 
    const [existingdDeviceTriggers, setExistingDevicetTriggers] = useState<string[]>([]); 
    const [deviceTriggers, setdDevicetTriggers] = useState<string[] | null>(null); 
    const [selectedTrigger, setSelectedTrigger] = useState<string | null>(null); 

    const [existingDeviceScripts, setExistingDeviceScripts] = useState<any[] | null>(null); 
    
    const [deviceScripts, setDeviceScripts] = useState<string[] | null>(null); 
    const [selectedDeviseScript, setSelectedDeviseScript] = useState<string >(''); 
    const [selectedDeviseScriptId, setSelectedDeviseScriptId] = useState<string | null>(null); 
    const [selectedDeviseScriptName, setSelectedDeviseScriptName] = useState<string | null>(null); 
    const [selectedDeviseScriptCodeName, setSelectedDeviseScriptCodeName] = useState<string | null>(null); 

    const [showTriggers, setShowTriggers] = useState(false); 
    const [allTriggers, setAllTriggers] = useState<any>([]);
    const [selectedTriggers, setSelectedTriggers] = useState<string[]>([]); 

    const [showTemplates, setShowTemplates] = useState(false); 
    const [allTemplates, setAllTemplates] = useState<any>([]); 
    const [selectedTemplates, setSelectedTemplates] = useState<any>([]); 


    let deviceData:any = {}; 

    const dispatch = useDispatch();
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };

    const getStoreName = (id:string) => {
        return (stores && stores.find((item:Store) => item.id === id)?.name || '');
    };
  
    const fetchDevices = async () => {
        setLoading(true);
        setDevices([]);
        organization_id && GetAllOrganizationDevices(organization_id)
            .then(data => {                             
                setDevices(data?.map((itm:Device) => ({
                    ...itm, store: getStoreName(itm.storeId || '')
                })));
                setStatuses(Array.from(new Set(data?.map(item => item.deviceStatus))));
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchDevice = async (id: string) => {
        if(id){
            setOpenDevModal(true);
            setModalLoading(true);
            GetDevice(organization_id, id)
                .then(dev => {
                    if(dev){
                        if(typeof dev.encodedDeviceData !== 'undefined' && dev.encodedDeviceData && !dev?.encodedDeviceData.includes('%')){
                            setModalDev({...dev, encodedDeviceData: '{}'});
                        } 
                        else setModalDev(dev);
                        if(dev.deviceType) {
                            fetchDeviceTypeData(dev.deviceType);
                            setSelectedModDeviceType(dev.deviceType);
                        }
                    }
                    setModalLoading(false);
                })
                .catch(error => console.error(error));
        }
    };
  
    const fetchZones = async () => {
        organization_id && GetAllZones(organization_id)
            .then(data => {    
                setZones(data);   
                fetchStores();
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };
  
    const fetchStores = async () => {
        organization_id && GetAllStores(organization_id)
            .then(data => {
                setStores(data);
                fetchDeviceTypes();
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };
  
    const fetchDeviceTypes = async () => {
        GetAllDeviceTypes()
            .then(data => {
                setDeviceTypes(data);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchDeviceScript = async (device_id:string, script_id:string) => {
        setLoadingScript(true);
        organization_id && 
        device_id && 
        script_id && 
            GetDeviceScript(organization_id, device_id, script_id)
            .then(data => {
                if(data){
                    setSelectedDeviseScript(decodeURIComponent(data.urlEncodedScript ? data.urlEncodedScript : "# Add your code here\n\n\n"));      
                    const selectedOption = existingDeviceScripts?.find((option) => option.scriptId === script_id);
                    setSelectedDeviseScriptName(selectedOption?.scriptName || null);
                    setSelectedDeviseScriptCodeName(selectedOption?.scriptTemplateName || null);
                    setLoadingScript(false);
                } else {
                    setSelectedDeviseScript(decodeURIComponent("# Add your code here\n\n\n"));  
                    selectedDeviseScriptCodeName && fetchDeviceTypeScriptTemplate(selectedDeviceType, selectedDeviseScriptCodeName);
                    setLoadingScript(false);
                }
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
                setLoadingScript(false);
            });
    };

    const fetchDeviceTypeData = async (device_type:string) => {
        organization_id && 
        device_type && 
            GetDeviceTypeData(device_type)
            .then(data => {
                setAllTriggers(data.deviceScriptTriggers); 
                setAllTemplates(data.deviceScripts);            
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchAllDeviceScriptTemplates = async (device_type:string) => {
        organization_id && 
        device_type && 
            GetAllDeviceScriptTemplates(device_type)
            .then(data => {
                setDeviceScriptTemplates(data);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchDeviceTypeScriptTemplate = async (device_type:string, script_template_name:string) => {
        organization_id && GetDeviceTypeScriptTemplate(device_type, script_template_name)
            .then(data => {
                setSelectedDeviseScript(decodeURIComponent(data ? data.urlEncodedScript : ''));      
                setSelectedDeviseScriptName(script_template_name);
                setSelectedDeviseScriptCodeName(script_template_name);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const newDevice = () => {  
        setEditId(null);
        setDeleteId(null);      
        setModalDev(defaultDevice);
        setOpenAddModal(true);
        setOpenDevModal(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchZones();
    },[]);
  
    useEffect(() => {        
        editId && fetchDevice(editId);      
    },[editId]);
    
    useEffect(() => {
        deviceTypes && fetchDevices();
    }, [deviceTypes]);
    
    useEffect(() => {
        setTmpDevices(devices);
    }, [devices]);
    
    useEffect(() => {
        selectedModDeviceType && fetchAllDeviceScriptTemplates(selectedModDeviceType);
    }, [selectedModDeviceType]);
    
    
    useEffect(() => {
        setExistingDevicetTriggers(modalDev.deviceScriptTriggers || []);
        setExistingDeviceScripts(modalDev.deviceScripts || []);
        setSelectedDeviseScript('');
    }, [modalDev]);
    
    useEffect(() => {        
        selectedDeviseScriptId && fetchDeviceScript(modalDev.id || '', selectedDeviseScriptId);
    }, [selectedDeviseScriptId]);
    
    useEffect(() => {
        setValidScript(false);
    }, [selectedDeviseScript]);

    useEffect(() => {
        setTmpDevices(devices.filter((item:any) => 
            ((item?.deviceName?.toLowerCase()+item?.store?.toLowerCase()+item?.deviceType?.toLowerCase()).includes(searchByName.toLowerCase())
            && (selectedStatus.length ? (item?.deviceStatus?.toLowerCase() === selectedStatus.toLowerCase()) : true)
            && (selectedStore.length ? (item?.storeId?.toLowerCase() === selectedStore.toLowerCase()) : true)
            && (selectedDeviceType.length ? (item?.deviceType?.toLowerCase() === selectedDeviceType.toLowerCase()) : true)
        )));
    }, [searchByName, selectedStatus, selectedDeviceType, selectedStore]);


    const capitalizeFirstLetter = (txt:string) => {
        return txt.charAt(0).toUpperCase() + txt.slice(1)
    }
  
    const updateDevice = async () => {
      try {
        // Use the same form to Create or Update Organization
        setLoading(true);
        setDevices([]);
        if(editId){
            UpdateDevice(organization_id, {...modalDev, encodedDeviceData: encodeURI(JSON.stringify(deviceData))})
                .then(dev => {
                    fetchDevices();
                    setOpenDevModal(false);
                    setOpenAddModal(false);
                    setEditId(null);
                    deviceData = defaultDevice; 
                })
                .catch(error => console.error(error));
        } else {
            CreateDevice(organization_id, modalDev)
                .then(user => {
                    fetchDevices();
                    setOpenDevModal(false);   
                    setOpenAddModal(false);  
                    setModalDev(defaultDevice);
                    deviceData = defaultDevice; 
                })
                .catch(error => console.error(error));
        }
      } catch (error) {
        console.log(error);
      }
    };

    const DeleteDev= async (id: string) => {
        if(id){
            try {
            setLoading(true);
            setDevices([]);
            DeleteDevice(organization_id, id)
                .then(dev => {
                    fetchDevices();
                    setDeleteId(null);
                })
                .catch(error => console.error(error));
            } catch (error) {
            console.log(error);
            }
        }
    };

    const deleteDevice = async (id: string) => {
      id && await DeleteDev(id);
      setOpenDevModal(false);
      setDeleteId(null);
    };
    
    const setDeleteList = (data:string[]) => {
        // console.log("Delete List: ", data);  
        toast.warning("We need to update API!");
    };
    
    const handleEditorChange = (value:any, event:any) => {
        setSelectedDeviseScript(value);
    };

    const evaluateScript = () => {
        // console.log(encodeURIComponent(selectedDeviseScript))
        if(selectedDeviseScript){
            try {
            setLoading(true);
            modalDev.id && 
            selectedDeviseScriptId &&
                EvaluateDeviceScript(organization_id, modalDev.id, selectedDeviseScriptId, {
                    urlEncodedScript: encodeURIComponent(selectedDeviseScript),
                    organizationId: organization_id,
                    deviceId: modalDev.id,
                    scriptId: selectedDeviseScriptId,
                    trigger: selectedTrigger,
                    scriptName: selectedDeviseScriptName,
                    scriptTemplateName: selectedDeviseScriptCodeName
                })
                .then(dev => {
                    setLoading(false);
                    if(dev?.status > 0){
                        toast.error("The code contains errors or issues. Please review and address them.");
                        setValidScript(false);
                    } else {
                        if(dev.scriptEvaluationResult.isSuccess){
                            toast.success("The code is valid and functions as expected.");
                            setValidScript(true);
                        } else {
                            toast.success("The code contains errors or issues. Please review and address them.");
                            setValidScript(false);
                        }
                    } 
                })
                .catch(error => {
                    console.error(error);
                    setLoading(false);
                    setValidScript(false);
                });
            } catch (error) {
                console.log(error);
                setLoading(false);
                setValidScript(false);
            }
        }
    };

    const updateScript = () => {
        // console.log(encodeURIComponent(selectedDeviseScript))
        if(selectedDeviseScript){
            try {
            setLoading(true);
            modalDev.id && 
            selectedDeviseScriptId &&
                UpdateDeviceScript(organization_id, modalDev.id, selectedDeviseScriptId, {
                    urlEncodedScript: encodeURIComponent(selectedDeviseScript),
                    organizationId: organization_id,
                    deviceId: modalDev.id,
                    scriptId: selectedDeviseScriptId,
                    trigger: selectedTrigger,
                    scriptName: selectedDeviseScriptName,
                    scriptTemplateName: selectedDeviseScriptCodeName
                })
                .then(dev => {
                    setLoading(false);
                    toast.success("Script is saved");
                })
                .catch(error => {
                    console.error(error);
                    setLoading(false);
                });
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    };

    const resetSelectedDevice = () => {
        setExistingDevicetTriggers([]); 
        setdDevicetTriggers(null); 
        setSelectedTrigger(null); 
        setExistingDeviceScripts(null); 
        setDeviceScripts(null); 
        setSelectedDeviseScript(''); 
        setSelectedDeviseScriptId(null); 
        setSelectedDeviseScriptName(null); 
        setSelectedDeviseScriptCodeName(null); 
        setModalDev(defaultDevice);
        setOpenAddModal(false);
        setOpenDevModal(false);
        setEditId(null);
        setDeleteId(null);  
        setValidScript(false);
        setShowTriggers(false); 
        setAllTriggers([]);
        setSelectedTriggers([]); 
        setShowTemplates(false); 
        setAllTemplates([]); 
        setSelectedTemplates([]); 
    };
    

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Devices',
            url: '/devices',
            current: true
        },
    ];

    const headCells: readonly HeadCell[] = [
        {
            id: 'deviceName',
            numeric: false,
            disablePadding: false,
            label: 'Device',
            type: 'string',
        },
        {
            id: 'deviceType',
            numeric: false,
            disablePadding: false,
            label: 'Type',
            type: 'chip',
        },
        {
            id: 'deviceStatus',
            numeric: false,
            disablePadding: false,
            label: 'Status',
            type: 'chip',
        },
        {
            id: 'store',
            numeric: false,
            disablePadding: false,
            label: 'Store',
            type: 'string',
        }
    ];


    const renderFilters = () => (
      <Fragment>
        <FormControl size="sm">
            <FormLabel>Type</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                value={selectedDeviceType}
                disabled={!deviceTypes}
                onChange={(e, newValue) => setSelectedDeviceType(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {deviceTypes && deviceTypes.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
            </Select>
        </FormControl>
        <FormControl size="sm">
            <FormLabel>Status</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                value={selectedStatus}
                disabled={!stores}
                onChange={(e, newValue) => setSelectedStatus(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {statuses.map((x:string) => x && <Option key={x} value={x}>{capitalizeFirstLetter(x.replaceAll('-',' ').replaceAll('_',' '))}</Option>)}
            </Select>
        </FormControl>
        <FormControl size="sm">
            <FormLabel>Store</FormLabel>
            <Select placeholder="All"
                size="sm"
                variant="soft"
                value={selectedStore}
                disabled={!stores}
                onChange={(e, newValue) => setSelectedStore(newValue !== null ? newValue : '')}
            >
                <Option value="">All</Option>
                {stores && stores.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
            </Select>
        </FormControl>
      </Fragment>
    );

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >                
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1634224147987-95d2b7679fb0?auto=format&w=1000&dpr=2'}
                    title={'Devices'}
                >
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => fetchDevices()}
                    >
                        <ReplayOutlinedIcon />
                    </Button>
                    <Button
                        color="primary"
                        startDecorator={<AddIcon />}
                        size="sm"
                        onClick={() => newDevice()}
                    >
                        Add Device
                    </Button>
                </AppHeader>

                {/* Filters - Mobile    */}
                <Sheet
                    className="SearchAndFilters-mobile"
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        my: 1,
                        gap: 1,
                    }}
                >
                    <Input
                        size="sm"
                        variant='soft'
                        placeholder="Search"
                        value={searchByName} onChange={e => setSearchByName(e.target.value)}
                        startDecorator={<SearchIcon />}
                        sx={{ flexGrow: 1 }}
                    />
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => setFiltersOpen(true)}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
                        <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderFilters()}
                            <Button color="primary" onClick={() => setFiltersOpen(false)}>
                            Submit
                            </Button>
                        </Sheet>
                        </ModalDialog>
                    </Modal>
                </Sheet>

                {/* Filters- Web */}

                <Box
                    className="SearchAndFilters-tabletUp"
                    sx={{
                    buserRadius: 'sm',
                    py: 2,
                    display: { xs: 'none', sm: 'flex' },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: { xs: '120px', md: '160px' },
                    },
                    }}
                >
                    <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for visitor</FormLabel>
                    <Input value={searchByName} onChange={e => setSearchByName(e.target.value)} size="sm" variant='soft' placeholder="Search" startDecorator={<SearchIcon />} />
                    </FormControl>
                    {renderFilters()}
                </Box>

                <AceGrid
                    headers={headCells}
                    data={tmpDevices}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                    deleteList={setDeleteList}
                    defaultSortBy={'deviceName'}
                    defaultSort={'desc'}
                    defaultId={'id'}
                />

                <DevicesList
                    devices={tmpDevices}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                />

            </Box>
            
            <Modal open={openDevModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick') resetSelectedDevice();
            }}>
                <ModalDialog layout='fullscreen' variant='plain'>
                    <ModalClose />
                    <DialogTitle>Edit Device</DialogTitle>
                    
                {modalLoading ? <LinearProgress
                    color="primary"
                    determinate={false}
                    size="sm"
                    variant="soft"
                    /> :
                    <>
                        <ModalOverflow sx={{ position: 'relative', padding: '0px' }}>
                            <span>Insert device information</span>
                            <Tabs aria-label="Basic tabs" orientation="horizontal" size="md" defaultValue={0}>
                                <TabList>
                                    <Tab>Details</Tab>
                                    <Tab>Settings</Tab>
                                    {loggedUser?.extension_customClaims?.includes('super.admin') && <Tab>Script</Tab>}
                                </TabList>
                                <TabPanel value={0}>                                    
                                    <Stack spacing={2} maxWidth={700}>
                                        <FormControl>
                                            <FormLabel>Name</FormLabel>
                                            <Input 
                                            required 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev.deviceName || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                deviceName: e.target.value
                                                }
                                            )}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Type</FormLabel>
                                            <Select placeholder="Select type"
                                                size="sm"
                                                variant="soft"
                                                value={modalDev?.deviceType}
                                                disabled={!deviceTypes}
                                                onChange={(e, newValue) => setModalDev({
                                                    ...modalDev,
                                                    deviceType: newValue !== null ? newValue : ''
                                                })}
                                            >
                                                {deviceTypes && deviceTypes.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Zone</FormLabel>
                                            <Select placeholder="Select zone"
                                                size="sm"
                                                variant="soft"
                                                value={modalDev?.zoneId}
                                                disabled={!zones}
                                                onChange={(e, newValue) => setModalDev({
                                                    ...modalDev,
                                                    zoneId: newValue !== null ? newValue : ''
                                                })}
                                            >
                                                {zones && zones.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Store</FormLabel>
                                            <Select placeholder="Select store"
                                                size="sm"
                                                variant="soft"
                                            value={modalDev?.storeId}
                                                disabled={!stores}
                                                onChange={(e, newValue) => setModalDev({
                                                    ...modalDev,
                                                    storeId: newValue !== null ? newValue : ''
                                                })}
                                            >
                                                {stores && stores.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Device Id</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev.ioTDeviceId || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                ioTDeviceId: e.target.value
                                                }
                                            )}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Device Status</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev.deviceStatus || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                deviceStatus: e.target.value
                                                }
                                            )}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Primary connection string</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev.ioTPrimaryConnectionString || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                ioTPrimaryConnectionString: e.target.value
                                                }
                                            )}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Secondary connection string</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev.ioTSecondaryConnectionString || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                ioTSecondaryConnectionString: e.target.value
                                                }
                                            )}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Primary key</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev.ioTPrimaryKey || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                ioTPrimaryKey: e.target.value
                                                }
                                            )}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Secondary key</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev.ioTSecondaryKey || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                ioTSecondaryKey: e.target.value
                                                }
                                            )}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>IP address</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev?.networkInfo?.ipAddress || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                networkInfo: {
                                                    ...modalDev.networkInfo,
                                                    ipAddress: e.target.value
                                                }
                                            })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>IP V6 address</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev?.networkInfo?.ipV6Address || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                networkInfo: {
                                                    ...modalDev.networkInfo,
                                                    ipV6Address: e.target.value
                                                }
                                            })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Mac address</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev?.networkInfo?.macAddress || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                networkInfo: {
                                                    ...modalDev.networkInfo,
                                                    macAddress: e.target.value
                                                }
                                            })}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Illustration url</FormLabel>
                                            <Input 
                                            variant="soft" 
                                            size="sm" 
                                            value={modalDev?.deviceIlustrationUrl || ''}
                                            onChange={(e) => setModalDev({
                                                ...modalDev,
                                                deviceIlustrationUrl: e.target.value
                                            })}
                                            />
                                        </FormControl>
                                        <Button type="submit" onClick={() => updateDevice()}>Save</Button>
                                    </Stack>
                                </TabPanel>
                                <TabPanel value={1}>              
                                    <Stack spacing={2}>
                                        <CssVarsProvider>
                                            <div style={{backgroundColor: '#ffffff', padding: '5px', borderRadius: '3px'}}>
                                                <JsonForms
                                                    schema={modalDev?.deviceSchema}
                                                    uischema={{type: "", ...modalDev?.deviceUiSchema}}
                                                    data={JSON.parse(decodeURI(modalDev?.encodedDeviceData || '{}'))}
                                                    renderers={materialRenderers}
                                                    cells={materialCells}
                                                    onChange={({ data, errors }) => {deviceData = data}}
                                                />
                                            </div>
                                        </CssVarsProvider>
                                        <Button type="submit" onClick={() => updateDevice()}>Save</Button>
                                    </Stack>
                                </TabPanel>
                                <TabPanel value={2}>              
                                    <Stack spacing={2}>
                                        <Stack spacing={2} flexDirection={{ sm: 'column', md: 'row' }} justifyContent={'space-between'} marginBottom={'0.5rem'}>
                                            <Stack spacing={2} gap={'.5rem'} flexDirection={{ sm: 'column', md: 'row' }} justifyContent={'space-between'} alignItems={{ sm: 'start', md: 'end' }}>
                                                <FormControl>
                                                    <FormLabel>Trigger</FormLabel>
                                                    <ButtonGroup aria-label="outlined primary button group">
                                                        <Select placeholder="Select type"
                                                            size="sm"
                                                            variant="soft"
                                                            value={selectedTrigger}
                                                            defaultValue={''}
                                                            disabled={!existingdDeviceTriggers}
                                                            onChange={(e, newValue) => {
                                                                setSelectedTrigger(newValue);
                                                                setSelectedDeviseScript('');
                                                            }}
                                                            sx={{flex: 1}}
                                                        >
                                                            <option disabled value='' hidden></option>
                                                            {existingdDeviceTriggers && existingdDeviceTriggers.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
                                                        </Select>
                                                        <Button sx={{flex: 0}} color="neutral" variant="solid" onClick={() => setShowTriggers(true)}><Add /></Button>
                                                    </ButtonGroup>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Template</FormLabel>
                                                    <ButtonGroup aria-label="outlined primary button group">
                                                        <Select placeholder="Select script template"
                                                            size="sm"
                                                            variant="soft"
                                                            value={selectedDeviseScriptId}
                                                            defaultValue={''}
                                                            disabled={!selectedTrigger}
                                                            onChange={(e, newValue) => {
                                                                setSelectedDeviseScriptId(newValue);
                                                            }}
                                                            sx={{flex: 1}}
                                                        >
                                                            <option disabled value='' hidden></option>
                                                            {existingDeviceScripts && existingDeviceScripts.filter(item => item.trigger === selectedTrigger).map((item, index) => <Option key={index} value={item.scriptId}>{item.scriptName}</Option>)}
                                                        </Select>
                                                        <Button sx={{flex: 0}} 
                                                            disabled={!selectedTrigger}
                                                            color="neutral" variant="solid" 
                                                            onClick={() => setShowTemplates(true)}
                                                        ><Add /></Button>
                                                    </ButtonGroup>
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Script name</FormLabel>
                                                    <Input 
                                                        required 
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={selectedDeviseScriptName || ''}
                                                        onChange={(e) => setSelectedDeviseScriptCodeName(e.target.value)}
                                                    />
                                                </FormControl>
                                                <FormControl>
                                                    <FormLabel>Script name</FormLabel>
                                                    <Input 
                                                        required 
                                                        variant="soft" 
                                                        size="sm" 
                                                        value={selectedDeviseScriptCodeName || ''}
                                                        onChange={(e) => setSelectedDeviseScriptName(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Stack>
                                            <Button disabled={!selectedDeviseScriptName} color="danger" startDecorator={<BugReportIcon />} type="submit" onClick={() => evaluateScript()}>Validate</Button>
                                        </Stack>
                                        {loadingScript &&
                                        <LinearProgress
                                            color="primary"
                                            size="sm"
                                            variant="soft"
                                            sx={{marginTop: '5px  0px -10px 0px !important'}}
                                        />}
                                        <Editor
                                            theme={localStorage.getItem('joy-mode') === "dark" ? "vs-dark" : "vs-light"}
                                            height="60vh"
                                            defaultLanguage="python"
                                            defaultValue={selectedDeviseScript}
                                            onChange={handleEditorChange}
                                            value={selectedDeviseScript || ""}
                                            // options={{readOnly: (selectedTrigger && selectedDeviseScript)?false:true}}
                                        />
                                        <Button type="submit" disabled={!validScript} onClick={() => updateScript()}>Save</Button>
                                    </Stack>
                                </TabPanel>
                            </Tabs>
                        </ModalOverflow>
                    </>}
                </ModalDialog>
            </Modal>
            
            <Modal open={openAddModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setModalDev(defaultDevice);
                    setOpenAddModal(false);
                    setOpenDevModal(false);
                    setEditId(null);
                    setDeleteId(null);    
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                    <ModalClose />
                    <DialogTitle>Add Device</DialogTitle>
                    
                {modalLoading ? <LinearProgress
                    color="primary"
                    determinate={false}
                    size="sm"
                    variant="soft"
                    /> :
                    <>
                        <DialogContent>Insert device information</DialogContent>
                    <Stack spacing={2}>
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input 
                            required 
                            variant="soft" 
                            size="sm" 
                            value={modalDev.deviceName || ''}
                            onChange={(e) => setModalDev({
                                ...modalDev,
                                deviceName: e.target.value
                                }
                            )}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Type</FormLabel>
                            <Select placeholder="Select type"
                                size="sm"
                                variant="soft"
                                value={modalDev?.deviceType}
                                disabled={!deviceTypes}
                                onChange={(e, newValue) => setModalDev({
                                    ...modalDev,
                                    deviceType: newValue !== null ? newValue : ''
                                })}
                            >
                                {deviceTypes && deviceTypes.map((item, index) => <Option key={index} value={item}>{item}</Option>)}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Zone</FormLabel>
                            <Select placeholder="Select zone"
                                size="sm"
                                variant="soft"
                                value={modalDev?.zoneId}
                                disabled={!zones}
                                onChange={(e, newValue) => setModalDev({
                                    ...modalDev,
                                    zoneId: newValue !== null ? newValue : ''
                                })}
                            >
                                {zones && zones.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Store</FormLabel>
                            <Select placeholder="Select store"
                                size="sm"
                                variant="soft"
                            value={modalDev?.storeId}
                                disabled={!stores}
                                onChange={(e, newValue) => setModalDev({
                                    ...modalDev,
                                    storeId: newValue !== null ? newValue : ''
                                })}
                            >
                                {stores && stores.map((item, index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                            </Select>
                        </FormControl>
                        <Button type="submit" onClick={() => updateDevice()}>Save</Button>
                    </Stack>
                    </>}
                </ModalDialog>
            </Modal>
            
            <Modal open={showTriggers} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setShowTriggers(false);
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                    <ModalClose />
                    <DialogTitle>All Triggers</DialogTitle>
                    <Stack spacing={2}>
                        <Select
                        placeholder="Select triggers"
                        multiple
                        onChange={(e, newValue) => setSelectedTriggers(newValue as string[])}
                        >
                            {allTriggers && allTriggers.map((item:any, index:any) => <Option key={index} value={item}>{item}</Option>)}
                        </Select>
                        <Button type="submit" onClick={() => {
                            setExistingDevicetTriggers(Array.from(new Set(existingdDeviceTriggers.concat(selectedTriggers))));
                            setSelectedTriggers([]);
                            setShowTriggers(false);
                        }}>Add</Button>
                    </Stack>
                </ModalDialog>
            </Modal>
            
            <Modal open={showTemplates} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setShowTemplates(false);
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                    <ModalClose />
                    <DialogTitle>All Templates</DialogTitle>
                    <Stack spacing={2}>
                        <Select
                        placeholder="Select templates"
                        multiple
                        onChange={(e, newValue) => {
                            const tmpTemplates = (newValue as string[]).map((item:any, index:any) => {
                                return {
                                    scriptId: uuid(),
                                    trigger: selectedTrigger || '',
                                    scriptName: item,
                                    scriptTemplateName: item
                                }
                            });
                            setSelectedTemplates(tmpTemplates);
                        }}
                        >
                            {deviceScriptTemplates && deviceScriptTemplates.map((item:any, index:any) => <Option key={index} value={item}>{item}</Option>)}
                        </Select>
                        <Button type="submit" onClick={() => {
                            setExistingDeviceScripts([...existingDeviceScripts || [], ...selectedTemplates || []]);
                            setSelectedTemplates([]);
                            setShowTemplates(false);
                        }}>Add</Button>
                    </Stack>
                </ModalDialog>
            </Modal>
            <AppDeleteItemModal
                title={'Delete device'}
                description={'Are you sure you want to delete device?'}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                deleteFunction={deleteDevice}
            />
        </Layout>
    );
};

export default Devices;