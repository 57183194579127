import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { jwtDecode } from "jwt-decode";

export interface AceState {
  jwt_token: string,
  logged: any,
}

const initialState: AceState = {
    jwt_token: "",
    logged: {},
}

export const aceSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<string>) => {
      const user:any = jwtDecode(action.payload);
      if(user.extension_customClaims !== ''){
      state.jwt_token = action.payload;      
      state.logged = jwtDecode(action.payload);
      } else {
        state.jwt_token = "";
        state.logged = {};
      }
    },
    logout: (state) => {
      state.jwt_token = "";
      state.logged = {};
    },
    changeOrganzation: (state, action: PayloadAction<string>) => {
      if(action.payload !== ''){
        state.logged.extension_organizationId = action.payload;
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { login, logout, changeOrganzation } = aceSlice.actions

export default aceSlice.reducer