import React, {useState, useEffect, Fragment} from 'react';
import { useNavigate } from "react-router-dom";
import { Box,
    Button,
    Stack, 
    DialogTitle, 
    DialogContent, 
    LinearProgress, 
    Stepper, 
    Step, 
    StepButton, 
    StepIndicator, 
    FormControl, 
    FormLabel, 
    Input, 
    Modal, 
    ModalDialog, 
    ModalClose, 
    Select, 
    Option,
    Sheet,
    Typography,
    Divider,
    Tabs,
    TabList,
    Tab,
    TabPanel
} from '@mui/joy';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/joy/IconButton';
import Check from '@mui/icons-material/Check';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { useSelector, useDispatch } from 'react-redux'
import { loading } from '../../../store/loadingSlice'
import { toast } from 'react-toastify';
import Layout from '../../../template/layout';

import {
    GetStoreGroupEdges,
    GetStoreGroupEdge,
    CreateStoreGroupEdge,
    UpdateStoreGroupEdge,
    DeleteStoreGroupEdge,
    GetStoreGroups,
    StoreGroupEdge,
    StoreGroup
} from '../../../api/storeGroups';

import { GetAllStores, Store } from '../../../api/stores';

import AppDeleteItemModal from '../../../components/AppDeleteItemModal';
import AceGrid, { HeadCell } from '../../../components/AceGrid';
import AppHeader from '../../../components/AppHeader';
import AppBreadcrumb, { NavLinks } from '../../../components/AppBreadcrumb';
import uuid from '../../../res/uuid';
import StoreGroupEdgesList from './storeGroupEdgesList';

const StoreGroupEdges = () => {

    const defaultStoreGroupEdge = {
        id: uuid(),
        organizationId: "",
        storeGroupId: "",
        storeId: "",
        storeName: ""
    };

    const navigate = useNavigate();
    const [storeGroupEdges, setStoreGroupEdges] = useState<StoreGroupEdge[]>([]);
    const [tmpStoreGroupEdges, setTmpStoreGroupEdges] = useState<StoreGroupEdge[]>(storeGroupEdges);
    const [stores, setStores] = useState<Store[]>([]);
    const [openStoreGroupsModal, setOpenStoreGroupsModal] = useState(false);  
    const [modalStoreGroupEdges, setModalStoreGroupEdges] = useState<StoreGroupEdge>(defaultStoreGroupEdge); 
    const [modalLoading, setModalLoading] = useState(false);
    const [editId, setEditId] = useState<string | null>(null); 
    const [deleteId, setDeleteId] = useState<string | null>(null); 
    const [searchByName, setSearchByName] = useState('');
    const [filtersOpen, setFiltersOpen] = useState(false);  

    const organization_id = localStorage.getItem('organization_id') || "";
    const store_group_id = localStorage.getItem('store_group_id') || "";
    const store_group_name = localStorage.getItem('store_group_name') || "";

    const dispatch = useDispatch();
  
    const setLoading = (load: boolean) => {
      dispatch(loading(load));
    };
  
    const fetchStoreGroupEdges = async () => {
        setLoading(true);
        setStoreGroupEdges([]);
        GetStoreGroupEdges(store_group_id, organization_id)
            .then(storeGroupEdges => {
                setStoreGroupEdges(storeGroupEdges);
                setLoading(false);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };

    const fetchStoreGroupEdge = async (edge_id: string) => {
        if(edge_id){
            setOpenStoreGroupsModal(true);
            setModalLoading(true);
            organization_id && GetStoreGroupEdge(edge_id, store_group_id, organization_id)
                .then(storeGroupEdge => {
                    setModalStoreGroupEdges(storeGroupEdge);
                    setModalLoading(false);
                })
                .catch(error => {
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };

    const deleteStoreGroupEdge = async (edge_id: string) => {
        if(edge_id){
            setLoading(true);            
            DeleteStoreGroupEdge(edge_id, store_group_id, organization_id)
                .then(storeGroupEdge => {
                    fetchStoreGroupEdges();
                    setOpenStoreGroupsModal(false);
                    setDeleteId(null);
                })
                .catch(error => {
                    console.log(error);
                    
                    toast.error(error.message);
                    setLoading(false);
                });
        }
    };
  
    const fetchStores = async () => {
        setLoading(true);
        organization_id && GetAllStores(organization_id)
            .then(stores => {
                setStores(stores);
            })
            .catch(error => {
                toast.error(error.message);
                setLoading(false);
            });
    };
  
    const updateStore = async () => {
        try {
            // Use the same form to Create or Update Store Group Edge
            setLoading(true);
            if(editId){
                UpdateStoreGroupEdge(editId, store_group_id, organization_id, modalStoreGroupEdges)
                    .then(storeGroupEdge => {
                        fetchStoreGroupEdges();
                        setOpenStoreGroupsModal(false);
                        setEditId(null);
                    })
                    .catch(error => {
                        toast.error(error.message);
                        setLoading(false);
                    });
            } else {
                CreateStoreGroupEdge(store_group_id, organization_id, modalStoreGroupEdges)
                    .then(storeGroupEdge => {
                        fetchStoreGroupEdges();
                        setOpenStoreGroupsModal(false);     
                        setModalStoreGroupEdges(defaultStoreGroupEdge);
                    })
                    .catch(error => {
                        toast.error(error.message);
                        setLoading(false);
                    });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const newStoreGroup = () => {  
        setEditId(null);
        setDeleteId(null);
        setModalLoading(false);
        setModalStoreGroupEdges(defaultStoreGroupEdge);
        setOpenStoreGroupsModal(true);
    };
  
    useEffect(() => {
      fetchStores();
    },[]);
  
    useEffect(() => {      
        editId && fetchStoreGroupEdge(editId);      
    },[editId]);
    
    useEffect(() => {
        stores && fetchStoreGroupEdges();
    }, [stores]);
    
    useEffect(() => {
        setTmpStoreGroupEdges(storeGroupEdges);
    }, [storeGroupEdges]);

    useEffect(() => {
        setTmpStoreGroupEdges(storeGroupEdges.filter((item:StoreGroupEdge) => 
            (item?.storeName.toLowerCase().includes(searchByName.toLowerCase())
        )));
    }, [searchByName]);
    
    const getStoreName = (id:string) => {
        return stores ? (stores.find((item:Store) => item.id === id)?.name || '') : '';
    };
    
    const setDeleteList = (data:string[]) => {
        // console.log("Delete List: ", data);  
        toast.warning("We need to update API!");
    };

    const navLinks:NavLinks[] = [
        {
            title: 'Dashboard',
            url: '/',
            current: false
        },
        {
            title: 'Store groups',
            url: '/store-groups',
            current: false
        },
        {
            title: 'Store group edges',
            url: '/store-group-edges',
            current: true
        },
    ];

    const headCells: readonly HeadCell[] = [
        {
            id: 'storeName',
            numeric: false,
            disablePadding: false,
            label: 'Name',
            type: 'string',
        }
    ];


    const renderFilters = () => (
      <Fragment>
      </Fragment>
    );

    return (
        <Layout>
            <Box
                component="main"
                className="MainContent"
                sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
                }}
                style={{
                position: 'relative',
                maxWidth: '1400px',
                margin: '0 auto'
                }}
            >                
                <AppBreadcrumb data={navLinks} />

                <AppHeader
                    background={'https://images.unsplash.com/photo-1647100626362-d729d13dae86?auto=format&w=1000&dpr=2'}
                    title={`${store_group_name}: Stores`}
                >
                    <Button
                        color="success"
                        size="sm"
                        onClick={() => fetchStoreGroupEdges()}
                    >
                        <ReplayOutlinedIcon />
                    </Button>
                    <Button
                        color="primary"
                        startDecorator={<AddIcon />}
                        size="sm"
                        onClick={() => newStoreGroup()}
                    >
                        Add Store
                    </Button>
                </AppHeader>

                {/* Filters - Mobile    */}
                <Sheet
                    className="SearchAndFilters-mobile"
                    sx={{
                        display: { xs: 'flex', sm: 'none' },
                        my: 1,
                        gap: 1,
                    }}
                >
                    <Input
                        size="sm"
                        variant='soft'
                        placeholder="Search"
                        value={searchByName} onChange={e => setSearchByName(e.target.value)}
                        startDecorator={<SearchIcon />}
                        sx={{ flexGrow: 1 }}
                    />
                    <IconButton
                        size="sm"
                        variant="outlined"
                        color="neutral"
                        onClick={() => setFiltersOpen(true)}
                    >
                        <FilterAltIcon />
                    </IconButton>
                    <Modal open={filtersOpen} onClose={() => setFiltersOpen(false)}>
                        <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {renderFilters()}
                            <Button color="primary" onClick={() => setFiltersOpen(false)}>
                            Submit
                            </Button>
                        </Sheet>
                        </ModalDialog>
                    </Modal>
                </Sheet>

                {/* Filters- Web */}

                <Box
                    className="SearchAndFilters-tabletUp"
                    sx={{
                    buserRadius: 'sm',
                    py: 2,
                    display: { xs: 'none', sm: 'flex' },
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: { xs: '120px', md: '160px' },
                    },
                    }}
                >
                    <FormControl sx={{ flex: 1 }} size="sm">
                    <FormLabel>Search for stores</FormLabel>
                    <Input value={searchByName} onChange={e => setSearchByName(e.target.value)} size="sm" variant='soft' placeholder="Search" startDecorator={<SearchIcon />} />
                    </FormControl>
                    {renderFilters()}
                </Box>

                <AceGrid
                    headers={headCells}
                    data={tmpStoreGroupEdges}
                    setEditId={setEditId}
                    setDeleteId={setDeleteId}
                    deleteList={setDeleteList}
                    defaultSortBy={'storeName'}
                    defaultSort={'desc'}
                    defaultId={'id'}
                />

                <StoreGroupEdgesList storeGroupEdges={tmpStoreGroupEdges} setEditId={setEditId} setDeleteId={setDeleteId} stores={stores}/>
            </Box>
            <Modal open={openStoreGroupsModal} onClose={(_event: React.MouseEvent<HTMLButtonElement>, reason: string) => {
                if(reason !== 'backdropClick'){
                    setModalStoreGroupEdges(defaultStoreGroupEdge);
                    setOpenStoreGroupsModal(false);
                    setEditId(null);  
                }
            }} >
                <ModalDialog layout='center' variant='plain'>
                <ModalClose />
                <DialogTitle>Stores</DialogTitle>
                {modalLoading && <LinearProgress
                    color="primary"
                    determinate={false}
                    size="sm"
                    variant="soft"
                    />}
                    <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        updateStore();
                    }}
                    >
                    <DialogContent>Select store</DialogContent>
                    <Stack spacing={2}>
                    <FormControl>
                        <Select
                        size="sm"
                        variant="soft"
                        value={modalStoreGroupEdges.storeId}
                        onChange={(e, newValue) => newValue && setModalStoreGroupEdges({
                            ...modalStoreGroupEdges,
                            organizationId: organization_id,
                            storeGroupId: store_group_id,
                            storeId: newValue,
                            storeName: getStoreName(newValue)
                        })}
                        >
                            {stores && stores.map((item, i) => <Option key={i} value={item.id}>{item.name}</Option>)}
                        </Select>
                    </FormControl>
                    <Button type="submit">Save</Button>
                    </Stack>
                    </form>
                </ModalDialog>
            </Modal>

            <AppDeleteItemModal
                title={'Delete store'}
                description={'Are you sure you want to delete store?'}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                deleteFunction={deleteStoreGroupEdge}
            />
        </Layout>
    );
};

export default StoreGroupEdges;