import React, { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography } from "@mui/joy";
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

export interface NavLinks {
    title: string,
    url: string,
    current: boolean
}

export interface props {
    data: NavLinks[]
}

const AppBreadcrumb = ({ data }: props) => {
    const navigate = useNavigate();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs
            size="sm"
            aria-label="breadcrumbs"
            separator={<ChevronRightRoundedIcon />}
            sx={{ pl: 0 }}
        >
            <Link
            underline="none"
            color="neutral"
            aria-label="Home"
            onClick={() => navigate('/')}
            >
            <HomeRoundedIcon />
            </Link>
            {data && data.map((item, i) => item.current ?
                <Typography key={i} color="primary" fontWeight={500} fontSize={12}>
                    {item.title}
                </Typography> : 
                <Link
                key={i}
                underline="hover"
                color="neutral"
                fontSize={12}
                fontWeight={500}
                onClick={() => navigate(item.url)}
                >
                    {item.title}
                </Link>       
            )}
        </Breadcrumbs>
        </Box>);
};
export default AppBreadcrumb;


// // Send from parent component

// const navLinks:NavLinks[] = [
//     {
//         title: 'Dashboard',
//         url: '/',
//         current: false
//     },
//     {
//         title: 'Visitors',
//         url: '/visitors',
//         current: true
//     },
// ];
// <AppBreadcrumb data={navLinks} />