import { configureStore } from '@reduxjs/toolkit'
import aceReducer from './aceSlice';
import loadingReducer from './loadingSlice';

export const store = configureStore({
    reducer: {
        auth: aceReducer,
        loading: loadingReducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AceState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch